import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/wsc.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import Heading from "../components/Heading/Heading";

import Basic from 'Routes/fetchGames'
import MobileDetect from "mobile-detect";

export interface PageCollectionProps {
  className?: string;
}


function dynamicSlider(){

  var md = new MobileDetect(window.navigator.userAgent);

  if (md.mobile() || md.tablet()) {
    var slider = document.getElementById("imageSlider");

    // @ts-ignore
    slider.src = "https://absolutecaresey.com/wp-content/uploads/2022/12/ccc.png"

  }
  else {
    var slider = document.getElementById("imageSlider");
    // @ts-ignore
    slider.src = "https://absolutecaresey.com/wp-content/uploads/2022/12/Untitled-1-01.jpg"
  }

}




const PageCollection: FC<PageCollectionProps> = ({ className = "" }) => {
  return (

    <div
      className={`nc-PageCollection  ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>Maz Online || Maz Millions Online</title>
      </Helmet>


      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">

          <NcImage
              id={"imageSlider"}
            containerClassName="absolute inset-0"
            src={"https://absolutecaresey.com/wp-content/uploads/2022/12/Untitled-1-01.jpg"}
            className="object-cover w-full h-full"
              onLoad={dynamicSlider}

          />

        </div>

      </div>
      {/* ====================== END HEADER ====================== */}

      <br/>
      <br/>

      <Heading
          isCenter={true}

          desc="~Choose a lottery below~"

      >
        Your chance to become a MILLIONAIRE!<h1>    </h1>
      </Heading>





      <Basic />

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-20 lg:space-y-28">


        {/* === SECTION 5 === */}


        {/* SUBCRIBES */}
        <SectionBecomeAnAuthor />
      </div>
    </div>
  );
};

export default PageCollection;
