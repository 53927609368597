import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import mazLogo from "../../images/ic_logo_maz.png";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [


  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
      { href: "#", label: "Code of Conduct" },

    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (

<div>

  <footer className="footer-distributed">

    <div className="footer-left">

      <img className={"mazLogoFooter"} src={mazLogo} alt="mazLogo" />



      <p className="footer-company-name">Maz Millions Seychelles © 2022</p>
    </div>

    <div className="footer-center">

      <div>
        <i className="fa fa-map-marker"></i>
        <p><span>The Quadrant Building</span> Victoria, Mahe , Seychelles</p>
      </div>

      <div>
        <i className="fa fa-phone"></i>
        <p>+2484326464</p>
      </div>

      <div>
        <i className="fa fa-envelope"></i>
        <p><a href="mailto:support@mazmillions.com">support@mazmillions.com</a></p>
      </div>

    </div>

    <div className="footer-right">

      <p className="footer-company-about">
        <span>About Maz Millions</span>
        MAZ MILLIONS <br/> is a lottery company based in Seychelles owned by Seychellois. The
        company holds a gambling license issued by the Seychelles Licensing Authority. Our
        office opened its doors on 1st December 2017 at Unity House, Victoria and in 2019
        moved to existing office at Quandrant House, also in Victoria. We also have an
        office at STC Hypermarket in Bois de Rose Avenue. We can be contacted by phone
        on 4326264 and 4326565 and by email at mazmillions@seychelles.net Currently,
        we have 2 weekly games running, MAZ 6/37 and 6/37 MAX. Both are drawn live on
        SBC 2 at 9.00 pm. You can also follow us on our Facebook page.
        <br/>
        <br/>
        <br/>

        <a className={"footerCont"} href="tel:2585055"><u>Maz Millions Software By E-Intelligence Seychelles ~ Founder Jean-Yves Morel</u></a>
      </p>

      <div className="footer-icons">



      </div>

    </div>

  </footer>
</div>
  );
};

export default Footer;
