
import axios from "axios";
import {useEffect, useState} from "react";
import React, { Component }  from 'react';
import {nftsImgs} from "../contains/fakeData";
import IMask from 'imask'
import VerifyIcon from "../components/VerifyIcon";
import Swal from 'sweetalert2'
import Vector1 from "../images/mazsixttznico.png";
import VectorMax from "../images/mazmax.png";
import CryptoJS from "crypto-js";
import qs from "qs";

 
export default function Basic() {


    var tries = 4
    var localUserStatus = false
 

        const [payAsMember, setPayAsMember] = useState(true);
        const [guestName, setGuestName] = useState('');

        const handleMemberClick = () => {
            setPayAsMember(false);
        };

        const handleGuestClick = () => {
            setPayAsMember(true);
        };

    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        // Add a timestamp to the generated ID to make it more unique
        const timestamp = new Date().getTime();
        result += timestamp;

        return result;
    };


    const paymentRequest = async (totalCost) => {
        // Assuming this is part of a functional component, no 'this' is used here


        let customersName = "TBD"
        let customersPhone = document.getElementById("customersPhone").value
         let customerEmailAdrress = "TBD"
        let customersNIN = "TBD"


        let totalTicketsPlayed = sessionStorage.getItem("ticketsPlayed")
        let eventGet = sessionStorage.getItem("Event")
        let gameName = sessionStorage.getItem("EventName")
        let totalCosting = ""

        let tempCustPhone = customersPhone
        customersPhone = "+248" + tempCustPhone

        if (gameName.includes("MAX")) {
            totalCosting = (200 * totalTicketsPlayed)
        } else {
            totalCosting = (50 * totalTicketsPlayed)
        }

        let playedNums = []

        for (var i = 1; i <= totalTicketsPlayed; i++) {

            playedNums[i - 1] = sessionStorage.getItem(`Ticket_${i}_Numbers`)
        }

        //console.log("PLAYED NUMS " + playedNums)





        try {
            const data = {
                amount: totalCosting,
                currency: "SCR",
                orderId: makeid(8),
                customersName: customersName ,
                customersPhone: customersPhone ,
                customerEmailAdrress: customerEmailAdrress ,
                customersNIN: customersNIN ,
                totalTicketsPlayed: totalTicketsPlayed,
                gameName: gameName,
                playedNums: playedNums,
                eventId: eventGet,
            };

            const headers = {
                'Content-Type': 'application/json',
            };

            //console.log(data)
            //console.log("data SET ABOVE")

            const mpgsInitCheckoutResponse = await axios({
                method: 'post',
                url: 'http://185.189.183.197/testSessionMaz.php',
                data: data,
                headers: headers,

            });

            // Print the raw response to the console
            //console.log('Raw Response from API:', mpgsInitCheckoutResponse);

            setTimeout(() => {
                window.Checkout.configure({
                    session: {
                        id: mpgsInitCheckoutResponse.data
                    }
                });
                // window.Checkout.showEmbeddedPage('#embed-target');
                window.Checkout.showPaymentPage();
            }, 1000);
        } catch (error) {
            alert("Failed to initiate session: " + error.message);
        }




    }




    function showCustomerNumbers() {


       let custNumView = document.getElementById("custNumView")
       let totalcostdetail = document.getElementById("totalcostdetail")
        let ticketAmt = sessionStorage.getItem("ticketsPlayed")


        var indivTicketPrice

      //  paymentRequest()







        for(var i = 1; i <= ticketAmt; i++)
        {
            let list1 = document.createElement('li')
            list1.className = "paypal__cart-item"

            let span1 = document.createElement('span')
            span1.className = "paypal__index"

            let span2 = document.createElement('span')
            span2.className = "paypal__item-name"

            let span3 = document.createElement('span')
            span3.className = "paypal__item-price"
            span3.id = "ticketprice"



            span1.innerText = (i ) + ")  "

            const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"
            let decrypted = CryptoJS.AES.decrypt(sessionStorage.getItem(`Ticket_${i}_Numbers`),secret)


            let data = decrypted.toString(CryptoJS.enc.Utf8)

            span2.innerText = data.replaceAll(",", "-")

            if(sessionStorage.getItem("Event") == "659a78b51f931cadb03bddb9")
            {
                span3.innerText = "SCR200"
                indivTicketPrice = 200
            }
            else {
                span3.innerText = "SCR50"
                indivTicketPrice = 50
            }




            custNumView.appendChild(list1)
            list1.appendChild(span1)
            list1.appendChild(span2)
            list1.appendChild(span3)


        }


        let totalcost = document.createElement('h2')
        totalcost.className = "paypal__cart-title2"

        totalcost.innerText = "Total Cost: SCR" + (parseInt(sessionStorage.getItem("ticketsPlayed")) * indivTicketPrice)

        totalcostdetail.appendChild(totalcost)

    }

    function loadTransProcessing(){

        let timerInterval
        window.scrollTo(0, 0);
        Swal.fire({
            icon: 'info',
            title: 'Transaction Processing!',
            html: 'We are processing your transaction, Please Wait! <br/> <br/>  1) Do not go back nor refresh the page while your transaction is processing  ' +
                ' <br/> <br/> 2) An Email and SMS will be sent to you with your transaction details  ' +
            ' <br/> <br/> 3) Contact support on 4326464 for queries regarding your purchase  ' ,
            allowOutsideClick: false,


            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {

                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                //console.log('I was closed by the timer')
            }
        })
    }


    function transactionSucess(){

        Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Your Transaction Was A Success!',
            footer: '✓',
            confirmButtonText: 'View Transaction',

        }).then(function() {
            window.location.href = "https://mazmillions.com/transactionView";
        });
    }

    function transactionFailure(){

        Swal.fire({
            icon: 'error',
            title: 'Oops..',
            html: 'Your Transaction Failed, Kindly check your card details' +
                '<br/> <br/> Your Transaction Failed, Kindly check your card details' +
                '<br/> <br/> 1) Check if your card is eligible for Internet Purchases' +
                '<br/> <br/> 2) Check if your account has sufficient funds' +
                '<br/> <br/> 3) Try Again',
            footer: 'Contact Support on 436',
            confirmButtonText: 'Try Again',
        })
    }



    function verifyLocal() {



       var verificationCode = Math.floor(Math.random() * (1000000 - 100000) + 100000)

        //console.log("The Verification Code Is: " + verificationCode)

        let customersPhone = document.getElementById("customersPhone").value



        var verCodeMessage = "Your Verification Code is: " + verificationCode
        
         // EMAIL SECTION

// Define the data to be sent in the POST request
const postData = {
    email: 'jean142000@live.com',
    body: 'Your verification code is: ' , // Include the generated code
    password: 'uiajhfui37982fgh8h3o8@*(!^#dghu1298@^ghuhwq90h'
};

// URL to which the POST request will be sent
const url2 = 'http://185.189.182.70/scratch.php';

// Send the POST request
axios.post(url2, qs.stringify(postData))
    .then(response => {
        console.log(response.data); // Log the response from the server
    })
    .catch(error => {
        console.error('There was a problem with the request:', error);
    });


            // EMAIL SECTION

        axios
            .get('https://cmp.cwseychelles.com/cgi-bin/BMP_SendTextMsg?UserName=MAZLOTTERY&PassWord=MyLcxD_123&UserData=' + verCodeMessage +'&Concatenated=1&SenderId=MAZMILLION%20R&Deferred=false&Number=' + customersPhone + '&Dsr=true')
            .then(res => {
                //console.log(`statusCode: ${res.status}`)
                //console.log(res)
            })
            .catch(error => {
                console.error(error)
            })
            
             

        Swal.fire({
            title: "Verification",
            text: "AA Verification code has been sent to " + customersPhone + ", Please enter the code before proceeding",
            input: 'number',
            showCancelButton: true
        }).then((result) => {
            if (result.value == verificationCode) {
                //console.log("Success, you are a local! : " + result.value);
                localUserStatus = true

               // TicketPuchase()
                loadTransProcessing()
                var totalCost = calculateTotal()
                paymentRequest(totalCost.toString());

            }
            else{
                localUserStatus = false
                //console.log("Failure, you are not local! "  );
                tries = tries - 1

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid Verification Code, Try Again! You have ' + tries + ' more tries',

                })

                if(tries == 0){
                    window.location.href = "https://mazmillions.com/online/";
                }
            }
        });

       return localUserStatus

    }

    function calculateTotal(){

       var amountOfTickets = sessionStorage.getItem("ticketsPlayed")
           var gamePlaying =  sessionStorage.getItem("EventName")

        var singleTicketPrice
        var totalCosting

        if(gamePlaying.includes("MM")){
            singleTicketPrice = 50
        }
        else if(gamePlaying.includes("MAX")){
            singleTicketPrice = 200
        }

        totalCosting = singleTicketPrice * amountOfTickets

        return totalCosting

    }

    async function officialTicketPuchase() {

         let customersPhone = document.getElementById("customersPhone").value


        //console.log( customersPhone)

        if ( customersPhone != "") {

            if (customersPhone.length == 7) {




                let totalTicketsPlayed = sessionStorage.getItem("ticketsPlayed")
                let eventGet = sessionStorage.getItem("Event")
                let gameName = sessionStorage.getItem("EventName")
                let totalCosting = ""

                let tempCustPhone = customersPhone
                customersPhone = "+248" + tempCustPhone

                if (gameName.includes("MAX")) {
                    totalCosting = (200 * totalTicketsPlayed)
                } else {
                    totalCosting = (50 * totalTicketsPlayed)
                }

                let playedNums = []

                for (var i = 1; i <= totalTicketsPlayed; i++) {

                    playedNums[i - 1] = sessionStorage.getItem(`Ticket_${i}_Numbers`)
                }

                //console.log("PLAYED NUMS " + playedNums)


                if (1+1 === 2) {

                    await verifyLocal()

                    //console.log(localUserStatus + ' the reTURN STATUS')




                   // loadTransProcessing()






                }


            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid Phone Number, Enter a correct number and try again!',

                })
            }


        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Please check all the checkout fields and try again!',

            })
        }


    }


    async function  TicketPuchase(memberNumber) {

        let customersName = "TBD"
        let customersPhone = document.getElementById("customersPhone").value
        let customerCardname = "TBD"
        let customerEmailAdrress = "TBD"
        let customersNIN = "TBD"


                let totalTicketsPlayed = sessionStorage.getItem("ticketsPlayed")
                let eventGet = sessionStorage.getItem("Event")
                let gameName = sessionStorage.getItem("EventName")
                let totalCosting = ""

                let tempCustPhone = customersPhone
                customersPhone = "+248" + tempCustPhone

                if (gameName.includes("MAX")) {
                    totalCosting = (200 * totalTicketsPlayed)
                } else {
                    totalCosting = (50 * totalTicketsPlayed)
                }

                let playedNums = []

                for (var i = 1; i <= totalTicketsPlayed; i++) {

                    playedNums[i - 1] = sessionStorage.getItem(`Ticket_${i}_Numbers`)
                }

                //console.log("PLAYED NUMS " + playedNums)



                    loadTransProcessing()

                    axios.post('https://fafafasfwq.website/purchaseonlinetransact', {customersNIN: customersNIN, customerEmailAdrress: customerEmailAdrress, totalCosting: totalCosting ,gameName:gameName,
                        eventId: eventGet , noOfTickets: totalTicketsPlayed , numbers: playedNums, customerPhoneNum: customersPhone, memberNumber: memberNumber,
                         })

                        .then(response => {
                            Swal.close()
                            //console.log( response.data)
                            //console.log( response.data.dataStatus + " DS ")

                            let fetchedDataStatus = response.data.dataStatus

                          
                              if((fetchedDataStatus === "APPROVED")){

                                transactionSucess()

                                for(var i = 0; i < response.data.purchasedSucceededTickets.length; i++)
                                {
                                    //console.log("LOOPING")
                                    sessionStorage.setItem(`SuccessTickets${i+1}`, response.data.purchasedSucceededTickets[i].numbers + "~" + response.data.purchasedSucceededTickets[i].ticketDisplayNumber)
                                }

                                    sessionStorage.setItem("transactionTimeStamp", response.data.transactionTimeStampString)
                                sessionStorage.setItem("transactionRefference", response.data.transactionId)
                                sessionStorage.setItem("customerPhone", customersPhone)
                                sessionStorage.setItem("customerName", customersName)
                                sessionStorage.setItem("customerEmail", customerEmailAdrress)

return


                            }

  else if(fetchedDataStatus === "DECLINED"){
                                transactionFailure()
                            }

                            // buildTable(response.data)


                        })
                        .catch(error => {
                            //console.log("error")
                            //console.log(error)
                        })





    }




    function setGameIcon(){

       var gameIco = document.getElementById("gameicon")

        let m637 = document.createElement('img')
        m637.className = "centerImg"
        m637.src = Vector1

        let mMAX = document.createElement('img')
        mMAX.className = "centerImg"
        mMAX.src = VectorMax


       if(sessionStorage.getItem("EventName").includes("6/37"))
       {
           gameIco.appendChild(m637)
       }

        if(sessionStorage.getItem("EventName").includes("MAX"))
        {
            gameIco.appendChild(mMAX)
        }


    }


    function loadTransProcessing(memberNumber, customerPhone, secretPIN) {
        let timerInterval;

        var totalCost = calculateTotal();

        window.scrollTo(0, 0);
        Swal.fire({
            icon: 'info',
            title: 'Transaction Processing!',
            html: 'We are processing your transaction, Please Wait! <br/> <br/>  1) Do not go back nor refresh the page while your transaction is processing  ' +
                ' <br/> <br/> 2) An Email and SMS will be sent to you with your transaction details  ' +
                ' <br/> <br/> 3) Contact support on 4326464 for queries regarding your purchase  ',
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                //console.log('I was closed by the timer');
            }
        });

        //console.log("CHECKING PLAYER ELIGIBILITY");
        // Post member number, customer phone, and secret PIN to verify purchase eligibility
        axios.post('https://fafafasfwq.website/verifypurchaseelegibility', {
            memberNumber: memberNumber,
            customerPhone: customerPhone,
            secretPIN: secretPIN,
            totalCost: totalCost
        })
            .then(response => {
                //console.log('Purchase eligibility verified:', response.data);
                // Check if the response status is SUCCESS_MATCH
                if (response.data.status === 'SUCCESS_MATCH') {
                    // If success, show success alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Your transaction is processed successfully.'
                    });

                    TicketPuchase(memberNumber)

                } else if (response.data.status === 'BALANCE_INSUFFICIENT') {
                    // If balance is insufficient, show top-up alert
                    Swal.fire({
                        icon: 'warning',
                        title: 'Insufficient Balance',
                        text: 'Your balance is insufficient for this transaction. Please top up your account.'
                    });
                } else {
                    // If not success, show error alert
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong with your transaction. Please try again later.'
                    });
                }
            })
            .catch(error => {
                console.error('Error verifying purchase eligibility:', error);
                // Show error alert if there is an error in the request
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong with your transaction. Please try again later.'
                });
            });
    }


    const handleMemberButtonClick = () => {
        const memberNumber = document.getElementById("membersNumber").value.trim();
        const customerPhone = document.getElementById("customersPhone").value.trim();
        const countryCode = document.getElementById('countryCodeSelect').value;

        // Check if both member number and customer phone contain data
        if (!memberNumber || !customerPhone) {
            // Display a Swal alert if any of them is missing data
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Missing data! Please fill in both the member number and customer phone number.',
            });
            return; // Exit the function early if data is missing
        }

        // Generate a random 6-digit code
        const generatedCode = Math.floor(100000 + Math.random() * 900000);
        
          // EMAIL SECTION
 

// Define the data to be sent in the POST request
            const postData = {
                email: 'jean142000@live.com',
                body: 'Your verification code is: ' ,
                password: 'uiajhfui37982fgh8h3o8@*(!^#dghu1298@^ghuhwq90h'
            };

// URL to which the POST request will be sent
            const url2 = 'http://185.189.182.70/scratch.php';

// Convert postData to form-encoded string
            const formData = qs.stringify(postData);

// Send the POST request
            axios.post(url2, formData)
                .then(response => {
                    console.log(response.data); // Log the response from the server
                })
                .catch(error => {
                    console.error('There was a problem with the request:', error);
                });



            // EMAIL SECTION
 console.log(customerPhone)
        //console.log('Generated Code:', generatedCode);

        // Send a request to verify the member
   axios.post('https://fafafasfwq.website/verifyMember', { memberNumber: memberNumber.trim(), customerPhone: customerPhone.trim(), generatedCode: generatedCode, countryCode: countryCode.trim() })
    .then(response => {
        // Log the response data
                console.log('Response data:');

        console.log('Response data:', response.data);
        
       console.log(customerPhone)
        // If the response is SUCCESS_MATCH, send the verification code via SMS
        if (response.data.status === 'SUCCESS_MATCH') {
            var smsOtp = "Please use OTP code: " + generatedCode + " to complete your online Maz Member Transaction, if you do not recognize this SMS please ignore";
            
             
             
            

            axios.get('https://cmp.cwseychelles.com/cgi-bin/BMP_SendTextMsg?UserName=MAZLOTTERY&PassWord=MyLcxD_123&UserData=' + smsOtp + '&Concatenated=1&SenderId=MAZMILLION%20R&Deferred=false&Number=' + customerPhone + '&Dsr=true')
                .then(response => {
                    // Proceed to prompt the user to enter the 6-digit code
                })
                .catch(error => {
                    console.error('Error sending SMS:', error);
                });
            promptUserForSixDigitCode(generatedCode, customerPhone, memberNumber);
        } else {
            // Proceed to prompt the user to enter the 6-digit code
            promptUserForSixDigitCode(generatedCode, customerPhone, memberNumber);
        }
    })
    .catch(error => {
        console.error('Error verifying member:', error);
    });

};

// Function to prompt the user to enter the 6-digit code
    const promptUserForSixDigitCode = (generatedCode, customerPhn, memberId) => {
        Swal.fire({
            title: 'OTP - Authentication',
            input: 'text',
            html: '<div>If your account matches the information we have on record, an OTP will be sent to your phone number</div>', // Add your subtitle here
            inputPlaceholder: 'Enter the OTP',
              inputAttributes: {
        inputmode: 'text' // This will ensure the keyboard displays both numbers and letters
    },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            preConfirm: (enteredCode) => {
                // Check if the entered code matches the generated code
                if (enteredCode === generatedCode.toString()) {
                    // Display success message if the codes match
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Code matched successfully! Please enter your secret PIN:',
                        input: 'password',
                        inputPlaceholder: 'Enter your secret PIN',
                        showCancelButton: true,
                        confirmButtonText: 'Submit',
                        showLoaderOnConfirm: true,
                        preConfirm: (pin) => {
                            // Display a Swal alert for processing transaction after secret PIN is entered
                            Swal.fire({
                                icon: 'info',
                                title: 'Transaction Processing!',
                                html: 'We are processing your transaction, Please Wait! <br/> <br/>  1) Do not go back nor refresh the page while your transaction is processing  ' +
                                    ' <br/> <br/> 2) An Email and SMS will be sent to you with your transaction details  ' +
                                    ' <br/> <br/> 3) Contact support on 4326464 for queries regarding your purchase  ',
                                allowOutsideClick: false,
                                timerProgressBar: true,
                            });

                            // You can perform additional validation or actions with the PIN here
                            ////console.log('Secret PIN:', pin);

                            loadTransProcessing(memberId, customerPhn, pin);

                            // Add your custom logic here
                        }
                    });
                } else {
                    // Display failure message if the codes don't match
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Code does not match! Please try again.',
                    });
                }
            }
        });
    };









    function clearOldTransData(){

        let ticketsPlayedFetched =  sessionStorage.getItem("ticketsPlayed")

        for(var i = 1; i<=ticketsPlayedFetched; i++){
            sessionStorage.removeItem(`SuccessTickets${i}`)

        }
        sessionStorage.removeItem("transactionTimeStamp")
        sessionStorage.removeItem("transactionRefference")
        sessionStorage.removeItem("customerPhone")
        sessionStorage.removeItem("customerName")
        sessionStorage.removeItem("customerEmail")
    }

    useEffect(() => {
        clearOldTransData();
        showCustomerNumbers();
        setGameIcon();
    }, []);

    useEffect(() => {
        if (!payAsMember) {
         //   paymentRequest();
        }
    }, [payAsMember]);




    // @ts-ignore
    return ( <>


        <div className="paypal">



            <div className="paypal__subheader-wrapper">
                <div className="paypal__subheader">

                    <h1 className="paypal__username">Lottery Selected: <br/> <br/> <span  className="paypal__help-text">{
                        sessionStorage.getItem("EventName")  }</span>

                        <br/>
                        <br/>
                        <div id={"gameicon"}>


                        </div>



                    </h1>

                </div>
            </div>

            <div className="paypal__cart">
                <h2 className="paypal__cart-title">Your Numbers:</h2>

                <ul id="custNumView" className="paypal__cart-list">



                </ul>


            </div>
            <div id={"totalcostdetail"}>


            </div>

            <div className="paypal__footer ">
                <h2 className="paypal__cart-title">Payment Details:</h2>

                <br/>



                {!payAsMember ? (

                        <>
                            <br/>
                            <br/>
                            <h2 className="paypal__cart-title">Customer Details:</h2>

                           <div className="field-container">
    <label className={"required"} htmlFor="customersPhone">Customers Phone Number</label>
    <div className="form-group mt-2 d-inline-block">
        <select id="countryCodeSelect" class="form-select border-end country-code px-2">
            <option value="+248" selected>+248</option>
            <option value="+61">+61</option>
            <option value="+1">+1</option>
        </select>
        <input id="customersPhone" type="text" className="form-control" pattern="[0-9]*" inputMode="numeric" />
    </div>
    <br/>
</div>


                            <br/>

                            <div className="field-container">
                                <label className={"required"}  htmlFor="customersName">Customers NIN Number</label>
                                <input id="customersNIN" type="text" className="form-control" pattern="[0-9]*" inputMode="numeric" required/>
                            </div>

                            <br/>

                            <div className="field-container">
                                <label className={"required"}  htmlFor="customersPhone">Customers Phone Number</label>
                                <div className="form-group mt-2 d-inline-block">

                                    <span className="border-end country-code px-2">+248</span>
                                    <input id="customersPhone" type="text" className="form-control" pattern="[0-9]*" inputMode="numeric" />
                                </div>
                                <br/>

                            </div>

                            <div className="field-container">
                                <label htmlFor="customersEmail">Customers Email Address</label>
                                <input id="customersEmail" type="text" inputMode="email"/>
                            </div>

                            <br/>
                            <div id='embed-target'> </div>

                            <div className="form-check">
                                <input type="checkbox" className="form-stay-logged-in-check-input" id="stay-logged-in-check" checked disabled />
                                <label className="form-stay-logged-in-check-label" htmlFor="stay-logged-in-check">  I confirm that i have read and agreed to Maz Millions Online Terms And Conditions <div className=" ">
                                    <span className="tc-apply">T&amp;Cs Apply</span>
                                    <div className="tc-desc"><strong>Understand Before Purchasing</strong>  Before Participating in Maz 6/37 or 6/37 MAX, Make sure
                                        you understand exactly how it works. Head over to our <a
                                            href="https://www.facebook.com/SeyMazMillion"> FACEBOOK PAGE</a> For a Tutorial on how to purchase tickets online if you are
                                        not sure how to purchase<em><br/>*You may also contact our office on 4326464 for details on how to purchase online*<br/> <br/>
                                            By proceeding, you understand that:<br/>
                                            1. You are 18 years of age and above <br/>
                                            2. Online Purchases are NON-REFUNDABLE <br/>
                                            3. You will receive an SMS and EMAIL with your ticket information<br/>
                                            4. It is your responsibility to download your E-Tickets after purchasing
                                        </em></div>
                                </div> </label>
                                <br/>
                                <br/>
                            </div>
                            <div>
                                <button  onClick={officialTicketPuchase} className={"button-rand"}> Purchase Tickets </button>

                            </div>
                        </>


                ) : (
                    <>
                        <br/>
                        <br/>
                        <div className="field-container">
                            <label className="required" htmlFor="membersNumber">
                                Member Number
                            </label>
                            <input
                                id="membersNumber"
                                type="text"
                                className="form-control"
                                pattern="[0-9]*"
                                inputMode="text"
                                required
                            />
                        </div>

                        <div className="field-container">
                            <label className="required" htmlFor="customersPhone">
                                Customer's Phone Number
                            </label>
                           <div className="form-group mt-2 d-inline-block">
                                <select id="countryCodeSelect" className="form-control">
                                    <option value="+248">+248 ( Seychelles )</option>
                                    <option value="+61">+61 ( Australia )</option>
                                    <option value="+18">+1 ( United States )</option>
                                </select>
                                <input
                                    id="customersPhone"
                                    type="text"
                                    className="form-control"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                />
                            </div>
                        </div>

<br/>
<br/>
                                 <div>
                                    <button onClick={handleMemberButtonClick} className={"button-rand"}> Purchase Tickets </button>
                                </div>


                    </>
                )}
            </div>


        </div>
        <br/>
        <br/>






    </> );
}



