

import axios from "axios";
import {useEffect, useState} from "react";
import React, { Component }  from 'react';
import {nftsImgs} from "../contains/fakeData";
import AddToHomeScreenPopup from './AddToHomeScreenPopup';


export default function Basic() {

    const [showAddToHomeScreenPopup, setShowAddToHomeScreenPopup] = useState(false);

    // Function to prompt the user to add the app to the home screen
    const promptUserToAddToHomeScreen = () => {
        // Check if the browser supports adding to the home screen
        if ('standalone' in window.navigator && window.navigator.standalone) {
            alert('You are already using the web app on your home screen.');
        } else if (window.matchMedia('(display-mode: standalone)').matches) {
            alert('You are already using the web app on your home screen.');
        } else {
            // Prompt the user to add to home screen
            // You can customize this part based on your needs
            alert('Please add this web app to your home screen for a better experience.');
        }
        setShowAddToHomeScreenPopup(false);


    };

    // Function to show the popup
    const showPopup = () => {
        setShowAddToHomeScreenPopup(false);
    };



    const [bestperformingdata, setBestperformingdata] = useState([


    ]);


    function changeImage(){
        let image = document.getElementById('imageSlider');
        let images = ['https://absolutecaresey.com/wp-content/uploads/2022/12/Untitled-1-01.jpg', 'https://img.freepik.com/free-psd/luxury-hexagonal-abstract-black-metal-background-with-golden-light-lines_271628-860.jpg?w=1380&t=st=1670809346~exp=1670809946~hmac=c3cc2d5f18ecd12bc419275c83b77bf7669f4603a88cf29e66b3a7aabf2a3cfd']
        setInterval (function(){
            let random = Math.floor(Math.random() * 2);
            // @ts-ignore
            image.src = images[random];
        }, 5000);
    }





    function  LoadAllGames(){
        document.getElementById("loadingScreen").style.display = "block"
        document.getElementById("gamesOffline").style.display = "none";
        ////console.log("Fetching all events")
        axios.post('https://fafafasfwq.website/campaignlist/activeshort', {type: "full"})
            .then(response => {




                ////console.log(" BPF data: ")



                ////console.log(" BPF data: " + bestperformingdata)
                ////console.log("success" + response.data.events.length)
                ////console.log(response.data.events)


                if(response.data.events.length > 0)
                {
                    document.getElementById("gamesOffline").style.display = "none";
                    ////console.log("PRESENT DATA" + response.data.events )
                    setBestperformingdata(response.data.events.reverse())

                    document.getElementById("loadingScreen").style.display = "none"
                }
                else {
                    ////console.log("NULLDATA")

                    document.getElementById("gamesOffline").style.display = "block";

                    document.getElementById("loadingScreen").style.display = "none"
                }


                //////console.log(" GAME DATA: " + this.state.gamedata)
            })
            .catch(error => {
                ////console.log("error")
                ////console.log(error)
            })

    }





    function selectLotteryClick(clicked_lottery)
    {

        ////console.log(clicked_lottery.target.id);

        var targetId = clicked_lottery.target.id
        var targetName = clicked_lottery.target.name
        var resultTime = clicked_lottery.target.className.substr(0,10)

        sessionStorage.setItem("Event", targetId)
        sessionStorage.setItem("EventName", targetName)
        sessionStorage.setItem("drawDate", resultTime)





     window.location.href = "http://mazmillions.com/maz637-game";

    }

    function clearOldTransData(){

        let ticketsPlayedFetched =  sessionStorage.getItem("ticketsPlayed")

        for(var i = 1; i<=ticketsPlayedFetched; i++){
            sessionStorage.removeItem(`SuccessTickets${i}`)
            sessionStorage.removeItem(`Ticket_${i}_Numbers`)

        }
        sessionStorage.removeItem("transactionTimeStamp")
        sessionStorage.removeItem("transactionRefference")
        sessionStorage.removeItem("SuccessTickets0")
        sessionStorage.removeItem("EventName")
        sessionStorage.removeItem("Event")
        sessionStorage.removeItem("ticketsPlayed")
        sessionStorage.removeItem("drawDate")
        sessionStorage.removeItem("customerPhone")
        sessionStorage.removeItem("customerName")
        sessionStorage.removeItem("customerEmail")

    }

    useEffect(()=> {

        clearOldTransData()
        LoadAllGames()
        showPopup()
        //console.clear()


    },  [])




        return ( <>


            <main id={"loadingScreen"}>
                {showAddToHomeScreenPopup && <AddToHomeScreenPopup />}
                <div className="dank-ass-loader">
                    <div className="row">
                        <div className="arrow up outer outer-18"></div>
                        <div className="arrow down outer outer-17"></div>
                        <div className="arrow up outer outer-16"></div>
                        <div className="arrow down outer outer-15"></div>
                        <div className="arrow up outer outer-14"></div>
                    </div>
                    <div className="row">
                        <div className="arrow up outer outer-1"></div>
                        <div className="arrow down outer outer-2"></div>
                        <div className="arrow up inner inner-6"></div>
                        <div className="arrow down inner inner-5"></div>
                        <div className="arrow up inner inner-4"></div>
                        <div className="arrow down outer outer-13"></div>
                        <div className="arrow up outer outer-12"></div>
                    </div>
                    <div className="row">
                        <div className="arrow down outer outer-3"></div>
                        <div className="arrow up outer outer-4"></div>
                        <div className="arrow down inner inner-1"></div>
                        <div className="arrow up inner inner-2"></div>
                        <div className="arrow down inner inner-3"></div>
                        <div className="arrow up outer outer-11"></div>
                        <div className="arrow down outer outer-10"></div>
                    </div>
                    <div className="row">
                        <div className="arrow down outer outer-5"></div>
                        <div className="arrow up outer outer-6"></div>
                        <div className="arrow down outer outer-7"></div>
                        <div className="arrow up outer outer-8"></div>
                        <div className="arrow down outer outer-9"></div>
                    </div>
                </div>
            </main>

            <div id={"gamesOffline"}>
                <div className="space-shortcode-wrap space-shortcode-11 relative">
                    <div className="space-shortcode-wrap-ins relative">
                        <div className="space-organizations-7-archive-items box-100 relative">
                            <div className="space-organizations-8-archive-item box-100 relative">
                                <div className="space-organizations-8-archive-item-ins relative">
                                    <div className="space-organizations-8-archive-item-bg box-100 relative">
                                        <div className="space-organizations-8-archive-item-left box-33 relative">
                                            <div className="space-organizations-8-archive-item-brand box-100 relative">
                                                <div className="space-organizations-8-archive-item-brand-logo box-40 relative"><a
                                                    title="Maz"> <img
                                                    width="270" height="270"
                                                    className="attachment-mercury-270-270 size-mercury-270-270" alt="Maz"
                                                    loading="lazy" srcSet={nftsImgs[1]}
                                                /> </a></div>
                                                <div className="space-organizations-8-archive-item-brand-logo box-40 relative"><a
                                                    title="Maz"> <img
                                                    width="270" height="270"
                                                    className="attachment-mercury-270-270 size-mercury-270-270" alt="Maz"
                                                    loading="lazy" srcSet={nftsImgs[0]}
                                                /> </a></div>
                                                <div className="space-organizations-8-archive-item-brand-logo box-40 relative"><a
                                                    title="Maz"> <img
                                                    width="270" height="270"
                                                    className="attachment-mercury-270-270 size-mercury-270-270" alt="Maz"
                                                    loading="lazy" srcSet={nftsImgs[0]}
                                                /> </a></div>
                                                <div className="space-organizations-8-archive-item-brand-name text-center box-60 relative">
                                                    <div className="space-organizations-8-archive-item-brand-name-link box-100 relative"><a
                                                        title="Maz"> <br/>  </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-organizations-8-archive-item-central box-33 relative">
                                            <div className="space-organizations-8-archive-item-ins-pd relative">
                                                <div className="space-organizations-8-archive-item-terms box-100 relative"><strong>Draw In Progress</strong> <br/> Unfortunately all games are now
                                                    offline, Come back later and try again</div>
                                            </div>
                                        </div>
                                        <div className="space-organizations-8-archive-item-right box-33 relative">
                                            <div className="space-organizations-8-archive-item-buttons box-100 relative">
                                                <div
                                                    className="space-organizations-8-archive-item-buttons-left box-50 text-center relative">


                                                </div>
                                                <div
                                                    className="space-organizations-8-archive-item-buttons-right box-50 text-center relative">
                                                    <div className="space-organizations-8-archive-item-button-three relative">
                                                        <a
                                                            title="Claim"

                                                        >Refresh</a>
                                                    </div>
                                                    <br/>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>



            {bestperformingdata.map((post, index) =>(



                    <>


                        <div >













                            <div className="space-shortcode-wrap space-shortcode-11 relative">
                                <div className="space-shortcode-wrap-ins relative">
                                    <div className="space-organizations-7-archive-items box-100 relative">
                                        <div className="space-organizations-8-archive-item box-100 relative">
                                            <div className="space-organizations-8-archive-item-ins relative">
                                                <div className="space-organizations-8-archive-item-bg box-100 relative">
                                                    <div className="space-organizations-8-archive-item-left box-33 relative">
                                                        <div className="space-organizations-8-archive-item-brand box-100 relative">
                                                            <div className="space-organizations-8-archive-item-brand-logo box-40 relative"><a
                                                                  title="Maz"> <img
                                                                width="270" height="270"  src={nftsImgs[index]}
                                                                className="attachment-mercury-270-270 size-mercury-270-270" alt="Maz"
                                                                loading="lazy"
                                                                srcSet={nftsImgs[index]}/> </a></div>
                                                            <div className="space-organizations-8-archive-item-brand-name text-center box-60 relative">
                                                                <div className="space-organizations-8-archive-item-brand-name-link box-100 relative"><a
                                                                     title="Maz">{post.raffleCode} -   {post.prefix}</a>
                                                                </div>
                                                                <div className="space-organizations-8-archive-item-stars-rating relative">
                                                                    <div className="star-rating">
                                                                        <div className="star-rating" > SCR{post.ticketPrice} </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="space-organizations-8-archive-item-central box-33 relative">
                                                        <div className="space-organizations-8-archive-item-ins-pd relative">
                                                            <div className="space-organizations-8-archive-item-terms box-100 relative"><strong id={"starPrize"}>Win: SCR {post.starPrize}</strong> <br/>Stand a chance to become
                                                                a MILLIONAIRE with Maz Millions 6/37 and Maz Millions MAX. For as low as SCR50, you can purchase a ticket and win
                                                                amazing cash prizes! Additional T&amp;Cs apply.</div>
                                                        </div>
                                                    </div>
                                                    <div className="space-organizations-8-archive-item-right box-33 relative">
                                                        <div className="space-organizations-8-archive-item-buttons box-100 relative">
                                                            <div
                                                                className="space-organizations-8-archive-item-buttons-left box-50 text-center relative">

                                                                <div className="space-organizations-8-archive-item-button-notice box-100 relative">
                                                                    <span className="tc-apply">T&amp;Cs Apply</span>
                                                                    <div className="tc-desc"><strong>Understand Before Purchasing</strong>  Before Participating in Maz 6/37 or 6/37 MAX, Make sure
                                                                        you understand exactly how it works. Head over to our <a
                                                                            href="https://www.facebook.com/SeyMazMillion"> FACEBOOK PAGE</a> For a Tutorial on how to purchase tickets online if you are
                                                                        not sure how to purchase<em><br/>*You may also contact our office on 4326464 for details on how to purchase online*
                                                                        </em></div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="space-organizations-8-archive-item-buttons-right box-50 text-center relative">
                                                                <div className="space-organizations-8-archive-item-button-two relative">
                                                                    <a
                                                                        title="Claim"
                                                                        className={post.resultTimeStamp}
                                                                        id={post.eventId} name={`${post.raffleCode} - ${post.name} \n ${post.prefix}`} onClick={e => selectLotteryClick(e, "id")} >Play Now</a>
                                                                </div>
                                                                <br/>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>







                        </div>






                    </>


                )

            )}


        </> );




}



