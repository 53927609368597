import React, { FC, Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import authorBanner from "images/nfts/authorBanner.png";
import collectionBanner from "images/nfts/wsc.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import VerifyIcon from "components/VerifyIcon";
import { Tab } from "@headlessui/react";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import ArchiveFilterListBox from "components/ArchiveFilterListBox";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";

import Basic from 'Routes/TransViewMechanics'


export interface TransactionViewProps {
    className?: string;
}



const TransactionView: FC<TransactionViewProps> = ({ className = "" }) => {
    let [categories] = useState([
        "Collectibles",
        "Created",
        "Liked",
        "Following",
        "Followers",
    ]);





    return (
        <div className={`nc-AuthorPage  ${className}`} data-nc-id="AuthorPage">
            <Helmet>
                <title>Maz Millions Lottery - Online</title>
            </Helmet>

            {/* HEADER */}
            <div className="w-full">
                <div className="relative w-full h-40 md:h-60 2xl:h-72">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={"https://absolutecaresey.com/wp-content/uploads/2022/12/Untitled-1-01.jpg"}
                        className="object-cover w-full h-full"
                    />
                </div>
                <div className="container -mt-10 lg:-mt-16">
                    <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">

                        <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
                            <div className="max-w-screen-sm ">











                                <Basic />

                            </div>
                            <div className="mt-4 ">

                            </div>
                        </div>

                    </div>

                    <br/>
                    <br/>
                    <br/>



                </div>
            </div>
            {/* ====================== END HEADER ====================== */}
            <body>
            <main>
                <div className="wrapper">




                </div>
            </main>
            </body>

        </div>
    );
};

export default TransactionView;
