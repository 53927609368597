

import axios from "axios";
import CryptoJS from "crypto-js";


import {useEffect, useState} from "react";
import React, { Component }  from 'react';
import {nftsImgs} from "../contains/fakeData";


export default function Basic() {

    var MobileDetect = require('mobile-detect')


    const [bestperformingdata, setBestperformingdata] = useState([


    ]);








    function  LoadAllGames(){
        ////console.log("Fetching all events")
        axios.post('http://fafafasfwq.website/campaignlist/activeshort', {type: "full"})
            .then(response => {



                ////console.log(" BPF data: ")



                ////console.log(" BPF data: " + bestperformingdata)
                ////console.log("success" + response.data.events)
                ////console.log(response.data.events)


                setBestperformingdata(response.data.events)

                //////console.log(" GAME DATA: " + this.state.gamedata)
            })
            .catch(error => {
                ////console.log("error")
                ////console.log(error)
            })

    }





    function selectLotteryClick(clicked_lottery)
    {

        ////console.log(clicked_lottery.target.id);

        var targetId = clicked_lottery.target.id


        let string = targetId.toString()

        const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

        let encrypted = CryptoJS.AES.encrypt(string,secret)



        sessionStorage.setItem("Event", encrypted)




        window.location.href = "http://mazmillions.com/maz637-game";

    }






function seelectAmt()
{

    var amount = 15;
    for (var i = 0; i < amount; i++){
        var new_div = document.createElement("div");
        new_div.className = "bluestrip";
        document.body.appendChild(new_div);
        ////console.log("This is repeat " + i);
    }
}

    function reply_click(clicked_id)
    {
        ////console.log("Inside Click")
        alert(clicked_id);

    }



    function createTicket1()
    {


        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                   //SETNULLHERE

                    sessionStorage.removeItem("Ticket_1_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_1_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden1");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count1, #matched-numbers1, #results1", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers1").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count1").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active1" : "";
                    matchedNumbersHtml += `<span class="Matchedball1 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers1").innerHTML = matchedNumbersHtml;
                show($(".output-box"));


            }
            function renderResults1(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball1 active result-number">${number}</span>`;
                }
                $("#results1").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers1").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball1" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector1"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball1[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active1");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check1").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball1[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active1");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check1").classList.add("disabled");
                    }
                });
            $$("#balls-selector1 .ball1", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check1").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    console.log("BIGONE " + JSON.stringify(numbers))



                    let string = JSON.stringify(numbers)



                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_1_Numbers", encrypted)
                    minimizeTicket(1)


                    ////console.log(numbers + " MATCHED NUMBAS")
                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart1").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_1_Numbers")
                maximizeTicket(1 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }



    function createTicket2()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_2_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_2_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden2");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count2, #matched-numbers2, #results2", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers2").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count2").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active2" : "";
                    matchedNumbersHtml += `<span class="Matchedball2 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers2").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults2(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball2 active result-number">${number}</span>`;
                }
                $("#results2").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers2").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball2" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector2"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball2[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active2");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check2").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball2[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active2");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check2").classList.add("disabled");
                    }
                });
            $$("#balls-selector2 .ball2", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check2").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)


                    sessionStorage.setItem("Ticket_2_Numbers", encrypted)
                    minimizeTicket(2)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart2").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_2_Numbers")
                maximizeTicket(2 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket3()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_3_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_3_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden3");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count3, #matched-numbers3, #results3", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers3").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count3").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active3" : "";
                    matchedNumbersHtml += `<span class="Matchedball3 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers3").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults3(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball3 active result-number">${number}</span>`;
                }
                $("#results3").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers3").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball3" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector3"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball3[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active3");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check3").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball3[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active3");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check3").classList.add("disabled");
                    }
                });
            $$("#balls-selector3 .ball3", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check3").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_3_Numbers", encrypted)
                    minimizeTicket(3)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart3").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_3_Numbers")
                maximizeTicket(3 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket4()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_4_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_4_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden4");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count4, #matched-numbers4, #results4", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers4").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count4").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active4" : "";
                    matchedNumbersHtml += `<span class="Matchedball4 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers4").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults4(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball4 active result-number">${number}</span>`;
                }
                $("#results4").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers4").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball4" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector4"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball4[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active4");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check4").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball4[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active4");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check4").classList.add("disabled");
                    }
                });
            $$("#balls-selector4 .ball4", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check4").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_4_Numbers", encrypted)
                    minimizeTicket(4)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart4").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_4_Numbers")
                maximizeTicket(4 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket5()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_5_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_5_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden5");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count5, #matched-numbers5, #results5", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers5").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count5").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active5" : "";
                    matchedNumbersHtml += `<span class="Matchedball5 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers5").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults5(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball5 active result-number">${number}</span>`;
                }
                $("#results5").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers5").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball5" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector5"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball5[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active5");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check5").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball5[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active5");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check5").classList.add("disabled");
                    }
                });
            $$("#balls-selector5 .ball5", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check5").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_5_Numbers", encrypted)
                    minimizeTicket(5)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart5").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_5_Numbers")
                maximizeTicket(5 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket6()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_6_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_6_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden6");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count6, #matched-numbers6, #results6", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers6").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count6").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active6" : "";
                    matchedNumbersHtml += `<span class="Matchedball6 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers6").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults6(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball6 active result-number">${number}</span>`;
                }
                $("#results6").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers6").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball6" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector6"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball6[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active6");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check6").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball6[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active6");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check6").classList.add("disabled");
                    }
                });
            $$("#balls-selector6 .ball6", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check6").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_6_Numbers", encrypted)
                    minimizeTicket(6)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart6").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_6_Numbers")
                maximizeTicket(6 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket7()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_7_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_7_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden7");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count7, #matched-numbers7, #results7", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers7").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count7").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active7" : "";
                    matchedNumbersHtml += `<span class="Matchedball7 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers7").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults7(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball7 active result-number">${number}</span>`;
                }
                $("#results7").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers7").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball7" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector7"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball7[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active7");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check7").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball7[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active7");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check7").classList.add("disabled");
                    }
                });
            $$("#balls-selector7 .ball7", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 7 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check7").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_7_Numbers", encrypted)
                    minimizeTicket(7)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart7").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_7_Numbers")
                maximizeTicket(7 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket8()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_8_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_8_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden8");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count8, #matched-numbers8, #results8", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers8").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count8").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active8" : "";
                    matchedNumbersHtml += `<span class="Matchedball8 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers8").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults8(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball8 active result-number">${number}</span>`;
                }
                $("#results8").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers8").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball8" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector8"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball8[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active8");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check8").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball8[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active8");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check8").classList.add("disabled");
                    }
                });
            $$("#balls-selector8 .ball8", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 8 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check8").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_8_Numbers", encrypted)
                    minimizeTicket(8)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart8").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_8_Numbers")
                maximizeTicket(8 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket9()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_9_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_9_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden9");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count9, #matched-numbers9, #results9", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers9").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count9").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active9" : "";
                    matchedNumbersHtml += `<span class="Matchedball9 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers9").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults9(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball9 active result-number">${number}</span>`;
                }
                $("#results9").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers9").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball9" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector9"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball9[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active9");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check9").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball9[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active9");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check9").classList.add("disabled");
                    }
                });
            $$("#balls-selector9 .ball9", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 9 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check9").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_9_Numbers", encrypted)
                    minimizeTicket(9)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart9").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_9_Numbers")
                maximizeTicket(9 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }


    function createTicket10()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_10_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_10_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden10");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count10, #matched-numbers10, #results10", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers10").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count10").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active10" : "";
                    matchedNumbersHtml += `<span class="Matchedball10 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers10").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults10(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball10 active result-number">${number}</span>`;
                }
                $("#results10").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers10").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball10" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector10"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball10[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active10");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check10").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball10[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active10");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check10").classList.add("disabled");
                    }
                });
            $$("#balls-selector10 .ball10", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 10 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check10").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_10_Numbers", encrypted)
                    minimizeTicket(10)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart10").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_10_Numbers")
                maximizeTicket(10 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket11()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_11_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_11_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden11");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count11, #matched-numbers11, #results11", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers11").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count11").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active11" : "";
                    matchedNumbersHtml += `<span class="Matchedball11 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers11").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults11(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball11 active result-number">${number}</span>`;
                }
                $("#results11").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers11").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball11" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector11"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball11[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active11");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check11").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball11[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active11");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check11").classList.add("disabled");
                    }
                });
            $$("#balls-selector11 .ball11", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 11 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check11").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_11_Numbers", encrypted)
                    minimizeTicket(11)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart11").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_11_Numbers")
                maximizeTicket(11 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket12()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_12_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_12_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden12");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count12, #matched-numbers12, #results12", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers12").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count12").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active12" : "";
                    matchedNumbersHtml += `<span class="Matchedball12 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers12").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults12(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball12 active result-number">${number}</span>`;
                }
                $("#results12").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers12").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball12" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector12"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball12[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active12");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check12").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball12[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active12");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check12").classList.add("disabled");
                    }
                });
            $$("#balls-selector12 .ball12", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 12 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check12").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_12_Numbers", encrypted)
                    minimizeTicket(12)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart12").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_12_Numbers")
                maximizeTicket(12 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket13()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_13_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_13_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden13");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count13, #matched-numbers13, #results13", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers13").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count13").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active13" : "";
                    matchedNumbersHtml += `<span class="Matchedball13 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers13").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults13(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball13 active result-number">${number}</span>`;
                }
                $("#results13").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers13").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball13" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector13"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball13[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active13");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check13").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball13[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active13");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check13").classList.add("disabled");
                    }
                });
            $$("#balls-selector13 .ball13", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 13 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check13").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_13_Numbers", encrypted)
                    minimizeTicket(13)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart13").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_13_Numbers")
                maximizeTicket(13 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket14()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_14_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_14_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden14");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count14, #matched-numbers14, #results14", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers14").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count14").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active14" : "";
                    matchedNumbersHtml += `<span class="Matchedball14 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers14").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults14(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball14 active result-number">${number}</span>`;
                }
                $("#results14").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers14").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball14" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector14"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball14[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active14");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check14").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball14[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active14");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check14").classList.add("disabled");
                    }
                });
            $$("#balls-selector14 .ball14", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 14 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check14").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_14_Numbers", encrypted)
                    minimizeTicket(14)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart14").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_14_Numbers")
                maximizeTicket(14 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket15()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_15_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_15_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden15");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count15, #matched-numbers15, #results15", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers15").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count15").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active15" : "";
                    matchedNumbersHtml += `<span class="Matchedball15 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers15").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults15(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball15 active result-number">${number}</span>`;
                }
                $("#results15").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers15").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball15" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector15"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball15[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active15");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check15").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball15[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active15");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check15").classList.add("disabled");
                    }
                });
            $$("#balls-selector15 .ball15", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 15 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check15").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_15_Numbers", encrypted)
                    minimizeTicket(15)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart15").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_15_Numbers")
                maximizeTicket(15 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket16()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_16_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_16_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden16");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count16, #matched-numbers16, #results16", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers16").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count16").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active16" : "";
                    matchedNumbersHtml += `<span class="Matchedball16 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers16").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults16(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball16 active result-number">${number}</span>`;
                }
                $("#results16").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers16").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball16" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector16"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball16[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active16");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check16").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball16[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active16");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check16").classList.add("disabled");
                    }
                });
            $$("#balls-selector16 .ball16", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 16 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check16").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_16_Numbers", encrypted)
                    minimizeTicket(16)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart16").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_16_Numbers")
                maximizeTicket(16 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket17()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_17_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_17_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden17");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count17, #matched-numbers17, #results17", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers17").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count17").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active17" : "";
                    matchedNumbersHtml += `<span class="Matchedball17 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers17").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults17(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball17 active result-number">${number}</span>`;
                }
                $("#results17").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers17").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball17" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector17"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball17[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active17");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check17").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball17[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active17");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check17").classList.add("disabled");
                    }
                });
            $$("#balls-selector17 .ball17", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 17 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check17").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_17_Numbers", encrypted)
                    minimizeTicket(17)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart17").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_17_Numbers")
                maximizeTicket(17 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket18()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_18_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_18_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden18");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count18, #matched-numbers18, #results18", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers18").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count18").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active18" : "";
                    matchedNumbersHtml += `<span class="Matchedball18 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers18").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults18(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball18 active result-number">${number}</span>`;
                }
                $("#results18").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers18").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball18" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector18"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball18[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active18");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check18").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball18[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active18");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check18").classList.add("disabled");
                    }
                });
            $$("#balls-selector18 .ball18", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 18 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check18").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_18_Numbers", encrypted)
                    minimizeTicket(18)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart18").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_18_Numbers")
                maximizeTicket(18 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket19()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_19_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_19_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden19");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count19, #matched-numbers19, #results19", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers19").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count19").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active19" : "";
                    matchedNumbersHtml += `<span class="Matchedball19 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers19").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults19(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball19 active result-number">${number}</span>`;
                }
                $("#results19").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers19").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball19" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector19"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball19[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active19");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check19").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball19[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active19");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check19").classList.add("disabled");
                    }
                });
            $$("#balls-selector19 .ball19", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 19 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check19").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_19_Numbers", encrypted)
                    minimizeTicket(19)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart19").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_19_Numbers")
                maximizeTicket(19 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

    function createTicket20()
    {

        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 37;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 0 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    sessionStorage.removeItem("Ticket_20_Numbers")
                    validatePurchase()
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    sessionStorage.removeItem("Ticket_20_Numbers")
                    validatePurchase()
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 0 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden20");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count20, #matched-numbers20, #results20", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers20").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count20").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active20" : "";
                    matchedNumbersHtml += `<span class="Matchedball20 ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers20").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults20(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball20 active result-number">${number}</span>`;
                }
                $("#results20").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers20").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball20" data-number="${n + 0}">${n +
                    0}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector20"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball20[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active20");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check20").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball20[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active20");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check20").classList.add("disabled");
                    }
                });
            $$("#balls-selector20 .ball20", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 20 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check20").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);

                    let string = JSON.stringify(numbers)

                    const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                    let encrypted = CryptoJS.AES.encrypt(string,secret)

                    sessionStorage.setItem("Ticket_20_Numbers", encrypted)
                    minimizeTicket(20)


                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart20").addEventListener("click", function(e) {
                sessionStorage.removeItem("Ticket_20_Numbers")
                maximizeTicket(20 )
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }

   function minimizeTicket(clicked_id )
    {

        var md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile() || md.tablet()) {
            var x = document.getElementById("pickerData" + clicked_id  );
            var y = document.getElementById("reactionButtons" + clicked_id);



            if (x.style.display === "none") {
                x.style.display = "block";
                y.style.display = "inline-block";
            } else {
                x.style.display = "none";
                y.style.display = "none";

            }
        }
        else {

        }





        validatePurchase()



    }

    function maximizeTicket(clicked_id )
    {




        var x = document.getElementById("pickerData" + clicked_id  );
        var y = document.getElementById("reactionButtons" + clicked_id);



        if (x.style.display === "none") {
            x.style.display = "block";
            y.style.display = "inline-block";
        }



        validatePurchase()



    }


    const shuffleTicket = e =>
    {

        ////console.log(`Shuffling${e.target.id.toString()}`)
//SHUFFLE
        let matchedNumbersHtml = `<span class="RandomVal 5">RANDOM</span>`

        document.getElementById(`matched-numbers${e.target.id.toString()}`  ).innerHTML = matchedNumbersHtml;
/*
        var rand1 =  Math.floor(Math.random() * (37 - 0) + 0);
        var rand2 =  Math.floor(Math.random() * (37 - 0) + 0);
        var rand3 =  Math.floor(Math.random() * (37 - 0) + 0);
        var rand4 =  Math.floor(Math.random() * (37 - 0) + 0);
        var rand5 =  Math.floor(Math.random() * (37 - 0) + 0);
        var rand6 =  Math.floor(Math.random() * (37 - 0) + 0);

        ////console.log(rand1 + "," + rand2 + "," + rand3 + "," + rand4 + "," + rand5 + "," + rand6)

        var arr = [];
        while(arr.length < 8){
            var r = Math.floor(Math.random() * 100) + 1;
            if(arr.indexOf(r) === -1) arr.push(r);
        }
        console.log(arr);


        var randomTicket = []


        randomTicket[0] = rand1
        randomTicket[1] = rand2
        randomTicket[2] = rand3
        randomTicket[3] = rand4
        randomTicket[4] = rand5
        randomTicket[5] = rand6

*/

        var randomTicket = [];
        while(randomTicket.length < 6){
            var r = Math.floor(Math.random() * (37 - 0) + 0 );
            if(randomTicket.indexOf(r) === -1) randomTicket.push(r);
        }
        console.log(randomTicket);




        console.log("all rands in" + randomTicket)
        //  = rand1 + "," + rand2 + "," + rand3 + "," + rand4 + "," + rand5 + "," + rand6


        let string = JSON.stringify(randomTicket)

        const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

        let encrypted = CryptoJS.AES.encrypt(string,secret)


        sessionStorage.setItem(`Ticket_${e.target.id.toString()}_Numbers`,  encrypted  )

        console.log("sessionSet")


        validatePurchase()


    }


    function createTicketUi(userValue)
    {

        for(var i = 1; i <= userValue; i++)
        {
            let div_wfull = document.createElement('div')


            let containerm10 = document.createElement('div')
            containerm10.className = "max-w-sm2"

            let relativemw = document.createElement('div')
            relativemw.className = "relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl2 flex flex-col md:flex-row"

            let pt_5 = document.createElement('div')


            let max_w = document.createElement('div')

            let b = document.createElement('b')
            b.className = "pcTextTicketTitle"

            let col = document.createElement('div')
            col.className = "pickrDta"
            col.id = "pickerData" + i.toString()


            let input_box2  = document.createElement('div')
            input_box2.className = "input-box"  + i.toString()
            input_box2.id = "input-box"  + i.toString()


            let ball_container  = document.createElement('div')
            ball_container.className = "balls-container" + i.toString()
            ball_container.id = "balls-selector" + i.toString()

            let hiddeninput  = document.createElement('input')
            hiddeninput.id = "input"
            hiddeninput.type = "hidden"

            let checkbutton  = document.createElement('button')
            checkbutton.className = "btn btn-primary disabled button-71 "
            checkbutton.id = "check" + i.toString()

            checkbutton.innerText = "Confirm"

            let output_box  = document.createElement('button')
            output_box.className = "output-box hidden2"
            output_box.id = "reactionButtons" + i.toString()

            let max6ticketslabel  = document.createElement('label')
            max6ticketslabel.className = "hidden"
            max6ticketslabel.htmlFor = "matched-count" + i.toString()
            max6ticketslabel.innerText = " Max Numbers Already selected "

            let spanlabel  = document.createElement('span')
            spanlabel.id = "matched-count" + i.toString()
            spanlabel.className = "hidden"

            let spanlabelseparator  = document.createElement('span')
            spanlabelseparator.className = "visible"

            spanlabelseparator.innerText = " ------------------------- "

            let matched_numbers20  = document.createElement('div')
            matched_numbers20.id = "matched-numbers"  + i.toString()
            matched_numbers20.className = "balls-container"  + i.toString()

            let clearbutton  = document.createElement('restart-box2')
            clearbutton.id = "restart"  + i.toString()
            clearbutton.className = "btn btn-success button-24"

            clearbutton.innerText = "Clear"

            let emptyspace  = document.createElement('br')
            let emptyspace1  = document.createElement('br')
            let emptyspace2  = document.createElement('br')
            let emptyspace3  = document.createElement('br')
            let emptyspace4  = document.createElement('br')



            let minimizeIcon  = document.createElement('i')
            minimizeIcon.className = "fa fa-heart"


            b.innerHTML = `Ticket ${i}`


            let minimizeIco = document.createElement('img')
            minimizeIco.className = "clearSelection"
            minimizeIco.src = "https://cdn-icons-png.flaticon.com/512/724/724979.png "

            minimizeIco.style.width = "20px"
            minimizeIco.id = `${i}`
            minimizeIco.onclick =  shuffleTicket

            let clearIco = document.createElement('img')
            clearIco.className = "clearSelection2"
            clearIco.src = "https://cdn-icons-png.flaticon.com/512/484/484662.png"

            clearIco.style.width = "20px"
            clearIco.id = "restart"  + i.toString()



            minimizeIco.innerText = "Ico"


            b.appendChild(clearIco)


            div_wfull.appendChild(containerm10)
            containerm10.appendChild(relativemw)

            relativemw.appendChild(pt_5)
            pt_5.appendChild(b)
            b.appendChild(minimizeIco)


            pt_5.appendChild(max_w)
            max_w.appendChild(col)

            // output_box.appendChild(clearbutton)
            output_box.appendChild(checkbutton)


           // col.innerHTML = `<button class="btn"><i class="fa fa-close"></i>&#xf01a;</button> `
            col.appendChild(input_box2)


            input_box2.appendChild(ball_container)
            ball_container.appendChild(hiddeninput)



            output_box.appendChild(max6ticketslabel)
            output_box.appendChild(spanlabel)
            output_box.appendChild(spanlabelseparator)


            output_box.appendChild(matched_numbers20)


            max_w.appendChild(output_box)












            let fes1 = document.getElementById("fes1")

                fes1.appendChild(div_wfull)



        }



    }

    function createTicketUiMobile(userValue)
    {

        for(var i = 1; i <= userValue; i++)
        {

            let ulmain = document.createElement('ul')
            ulmain.className = "list-view"

            let li = document.createElement('li')


            let a = document.createElement('a')
            let a2 = document.createElement('a')
            let b = document.createElement('b')

            let li2 = document.createElement('li')
            li2.className = "list-divider2"

            let li3 = document.createElement('li')


            let minimizeIco = document.createElement('img')
            minimizeIco.className = "clearSelection"
            minimizeIco.src = "https://cdn-icons-png.flaticon.com/512/2985/2985149.png  "

            minimizeIco.style.width = "20px"
            minimizeIco.id = `Ticket ${i}`
            minimizeIco.onclick =  function minimizeTicket(clicked_id )
            {



                var extractedInt = this.id.toString().replace( /^\D+/g, '')





                if(document.getElementById("randValueBadge" + extractedInt).style.display != "none")
                {
                    document.getElementById("randValueBadge" + extractedInt).style.display = "none"
                    sessionStorage.removeItem(`Ticket_${extractedInt}_Numbers`)
                }

                ////console.log("mini" + extractedInt )

                var x = document.getElementById("pickerData" + extractedInt  );
                var y = document.getElementById("reactionButtons" + extractedInt);

                var storedTicket1 = sessionStorage.getItem("Ticket_1_Numbers")
                var storedTicket2 = sessionStorage.getItem("Ticket_2_Numbers")

                if (x.style.display === "none") {
                    x.style.display = "block";
                    y.style.display = "inline-block";




                    document.getElementById("pickMyselfButton" + extractedInt).style.display = "none"
                    document.getElementById("randomizeButton" + extractedInt).style.display = "none"




                } else {
                    x.style.display = "none";
                    y.style.display = "none";

                    document.getElementById("pickMyselfButton" + extractedInt).style.display = "block"
                    document.getElementById("randomizeButton" + extractedInt).style.display = "block"

                    if(storedTicket1 != null){
                        document.getElementById("pickMyselfButton" + 1).style.display = "none"
                        document.getElementById("randomizeButton" + 1).style.display = "none"
                    }
                    if(storedTicket2 != null){
                        document.getElementById("pickMyselfButton" + 2).style.display = "none"
                        document.getElementById("randomizeButton" + 2).style.display = "none"
                    }
                }



                validatePurchase()




            }








            b.innerHTML = `Ticket ${i}`

            b.appendChild(minimizeIco)



            let clearIco = document.createElement('img')
            clearIco.className = "clearSelection2"
            clearIco.src = "https://cdn-icons-png.flaticon.com/512/484/484662.png"

            clearIco.style.width = "20px"
            clearIco.id = "restart"  + i.toString()



            minimizeIco.innerText = "Ico"


            b.appendChild(clearIco)


            /*
            const othermonke = document.createElement("img");
   othermonke.src = "https://via.placeholder.com/200";
   othermonke.width = "200"; // Better practice is to use setAttribute here
   othermonke.height = "200";
   othermonke.alt = "alttext";



            <div class="play-block-heading ng-scope" data-ng-if="key == 0">
                          <div class="play-block-heading-text ng-binding">
                            Choose 5 numbers                          </div>

                          <div class="right">
                              <div id="pick_remove" style="min-width:20px;">
                            <span data-icon="o" id="icon_12" class="icon" data-ng-click="clear(addTicket(carousel, ticket))" style="cursor: pointer;"></span>
                              </div>
                          </div>
                        </div>
             */


            let buttonClear = document.createElement('button')
            buttonClear.className = "btn btn-primary disabled button-77"

            buttonClear.innerText = "Randomize"

            /*


            <button class='space-organizations-8-archive-item-button-two relative'>Quick Pick</button>
             */

            //  b.appendChild(buttonClear)

            ulmain.appendChild(li)
            //ulmain.appendChild(li2)
            // li2.innerText = " tst "

            ulmain.appendChild(li3)
            li3.appendChild(a2)


            li.appendChild(a)
            a.appendChild(b)



            let div_wfull = document.createElement('div')


            let containerm10 = document.createElement('div')
            containerm10.className = "max-w-sm2"

            let relativemw = document.createElement('div')
            relativemw.className = ""

            let pt_5 = document.createElement('div')


            let max_w = document.createElement('div')


            let col = document.createElement('div')
            col.className = "pickrDta"
            col.id = "pickerData" + i.toString()


            let input_box2  = document.createElement('div')
            input_box2.className = "input-box"  + i.toString()
            input_box2.id = "input-box"  + i.toString()


            let randomizeButton  = document.createElement('button')
            randomizeButton.className = "button-rand"
            randomizeButton.id = "randomizeButton" + i.toString()
            randomizeButton.innerText = "Random"
           randomizeButton.onclick = function randomizeVal()
            {
               

                ////console.log(rand1 + "," + rand2 + "," + rand3 + "," + rand4 + "," + rand5 + "," + rand6)

                var extractedInt = this.id.toString().replace( /^\D+/g, '')

               var randomTicket = [];
        while(randomTicket.length < 6){
            var r = Math.floor(Math.random() * (37 - 0) + 0 );
            if(randomTicket.indexOf(r) === -1) randomTicket.push(r);
        }
        console.log(randomTicket);


                console.log("all rands in" + randomTicket)
                  //  = rand1 + "," + rand2 + "," + rand3 + "," + rand4 + "," + rand5 + "," + rand6


                let string = JSON.stringify(randomTicket)

                const secret = "wWenCrypTDaTTAB33TTRThnUc4NHA(Km1G@y78|2e4$$wd"

                let encrypted = CryptoJS.AES.encrypt(string,secret)


                sessionStorage.setItem(`Ticket_${extractedInt}_Numbers`,  encrypted  )

                console.log("sessionSet")
                document.getElementById("randomizeButton" + extractedInt).style.display = "none"
                document.getElementById("pickMyselfButton" + extractedInt).style.display = "none"
                document.getElementById("randValueBadge" + extractedInt).style.display = "block"

                validatePurchase()
            }


            let randValueBadge  = document.createElement('span')
            randValueBadge.className = "label info"
            randValueBadge.id = "randValueBadge" + i.toString()
            randValueBadge.innerText = "Randomized ✓"
            randValueBadge.style.display = "none"


            let pickMyselfButton  = document.createElement('button')
            pickMyselfButton.className = "button-pick"
            pickMyselfButton.id = "pickMyselfButton" + i.toString()
            pickMyselfButton.innerText = "Pick Myself"
            pickMyselfButton.onclick = function maximizeTicket( )
            {

                var extractedInt = this.id.toString().replace( /^\D+/g, '')


                var x = document.getElementById("pickerData" + extractedInt  );
                var y = document.getElementById("reactionButtons" + extractedInt);



                if (x.style.display === "none") {
                    x.style.display = "block";
                    y.style.display = "inline-block";
                }

                document.getElementById("pickMyselfButton" + extractedInt).style.display = "none"
                document.getElementById("randomizeButton" + extractedInt).style.display = "none"


            }


            let ball_container  = document.createElement('div')
            ball_container.className = "balls-container" + i.toString()
            ball_container.id = "balls-selector" + i.toString()

            let hiddeninput  = document.createElement('input')
            hiddeninput.id = "input"
            hiddeninput.type = "hidden"

            let checkbutton  = document.createElement('button')
            checkbutton.className = "btn btn-primary disabled button-71 "
            checkbutton.id = "check" + i.toString()

            checkbutton.innerText = "✓"

            let output_box  = document.createElement('button')
            output_box.className = "output-box hidden2"
            output_box.id = "reactionButtons" + i.toString()

            let max6ticketslabel  = document.createElement('label')
            max6ticketslabel.className = "hidden"
            max6ticketslabel.htmlFor = "matched-count" + i.toString()
            max6ticketslabel.innerText = " Max Numbers Already selected "

            let spanlabel  = document.createElement('span')
            spanlabel.id = "matched-count" + i.toString()
            spanlabel.className = "hidden"

            let spanlabelseparator  = document.createElement('span')
            spanlabelseparator.className = "visible"

            spanlabelseparator.innerText = " ------------------------- "



            let clearbutton  = document.createElement('restart-box2')
            clearbutton.id = "restart"  + i.toString()
            clearbutton.className = "btn btn-success button-24"

            clearbutton.innerText = "X"

            let emptyspace  = document.createElement('br')
            let emptyspace1  = document.createElement('br')
            let emptyspace2  = document.createElement('br')
            let emptyspace3  = document.createElement('br')
            let emptyspace4  = document.createElement('br')


            let minimizeIcon  = document.createElement('i')
            minimizeIcon.className = "fa fa-heart"



            let matched_numbers20  = document.createElement('div')
            matched_numbers20.id = "matched-numbers"  + i.toString()
            matched_numbers20.className = "balls-container"  + i.toString()



            a2.appendChild(randomizeButton)
            a2.appendChild(pickMyselfButton)
            a2.appendChild(randValueBadge)


            a2.appendChild(div_wfull)
            div_wfull.appendChild(containerm10)
            containerm10.appendChild(relativemw)
            relativemw.appendChild(pt_5)
            pt_5.appendChild(max_w)
            max_w.appendChild(col)

            //  output_box.appendChild(clearbutton)
            output_box.appendChild(checkbutton)


            // col.innerHTML = `<button class="btn"><i class="fa fa-close"></i>&#xf01a;</button> `
            col.appendChild(input_box2)



            input_box2.appendChild(ball_container)




            ball_container.appendChild(hiddeninput)



            output_box.appendChild(max6ticketslabel)
            output_box.appendChild(spanlabel)
            output_box.appendChild(spanlabelseparator)





            max_w.appendChild(output_box)





            max_w.appendChild(matched_numbers20)






            let fes1 = document.getElementById("fes1")

            fes1.appendChild(ulmain)



        }



    }


    function validatePurchase()
    {

        var tickAmt = document.getElementById("pets").value
        var storedNum1
        var storedNum2
        var storedNum3
        var storedNum4
        var storedNum5
        var storedNum6
        var storedNum7
        var storedNum8
        var storedNum9
        var storedNum10
        var storedNum11
        var storedNum12
        var storedNum13
        var storedNum14
        var storedNum15
        var storedNum16
        var storedNum17
        var storedNum18
        var storedNum19
        var storedNum20

        var md = new MobileDetect(window.navigator.userAgent);

        if (md.mobile() || md.tablet()) {
            switch (tickAmt) {
                case "1":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    if(storedNum1 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "2":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")

                    if(storedNum1 != null && storedNum2 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "3":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "4":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "5":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "6":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "7":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "8":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "9":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "10":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "11":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "12":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "13":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "14":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "15":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "16":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "17":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "18":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "19":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    storedNum19 = sessionStorage.getItem(`Ticket_19_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null && storedNum19 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "20":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    storedNum19 = sessionStorage.getItem(`Ticket_19_Numbers`)
                    storedNum20 = sessionStorage.getItem(`Ticket_20_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null && storedNum19 != null && storedNum20 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;

                default:

            }
        }
        else {
            switch (tickAmt) {
                case "1":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    if(storedNum1 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "2":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")

                    if(storedNum1 != null && storedNum2 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "3":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "4":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "5":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "6":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "7":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "8":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "9":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "10":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "11":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "12":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "13":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "14":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "15":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "16":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "17":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "18":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "19":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    storedNum19 = sessionStorage.getItem(`Ticket_19_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    ////console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null && storedNum19 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;
                case "20":
                    storedNum1 = sessionStorage.getItem(`Ticket_1_Numbers`)
                    storedNum2 = sessionStorage.getItem(`Ticket_2_Numbers`)
                    storedNum3 = sessionStorage.getItem(`Ticket_3_Numbers`)
                    storedNum4 = sessionStorage.getItem(`Ticket_4_Numbers`)
                    storedNum5 = sessionStorage.getItem(`Ticket_5_Numbers`)
                    storedNum6 = sessionStorage.getItem(`Ticket_6_Numbers`)
                    storedNum7 = sessionStorage.getItem(`Ticket_7_Numbers`)
                    storedNum8 = sessionStorage.getItem(`Ticket_8_Numbers`)
                    storedNum9 = sessionStorage.getItem(`Ticket_9_Numbers`)
                    storedNum10 = sessionStorage.getItem(`Ticket_10_Numbers`)
                    storedNum11 = sessionStorage.getItem(`Ticket_11_Numbers`)
                    storedNum12 = sessionStorage.getItem(`Ticket_12_Numbers`)
                    storedNum13 = sessionStorage.getItem(`Ticket_13_Numbers`)
                    storedNum14 = sessionStorage.getItem(`Ticket_14_Numbers`)
                    storedNum15 = sessionStorage.getItem(`Ticket_15_Numbers`)
                    storedNum16 = sessionStorage.getItem(`Ticket_16_Numbers`)
                    storedNum17 = sessionStorage.getItem(`Ticket_17_Numbers`)
                    storedNum18 = sessionStorage.getItem(`Ticket_18_Numbers`)
                    storedNum19 = sessionStorage.getItem(`Ticket_19_Numbers`)
                    storedNum20 = sessionStorage.getItem(`Ticket_20_Numbers`)
                    ////console.log(storedNum1 + " STORED NUMBA ONE ")
                    ////console.log(storedNum2 + " STORED NUMBA TWO ")
                    ////console.log(storedNum3 + " STORED NUMBA three ")
                    ////console.log(storedNum4 + " STORED NUMBA four ")
                    ////console.log(storedNum5 + " STORED NUMBA four ")
                    console.log(storedNum6 + " STORED NUMBA sx ")

                    if(storedNum1 != null && storedNum2 != null && storedNum3 != null && storedNum4 != null && storedNum5 != null && storedNum6 != null  && storedNum7 != null
                        && storedNum8 != null && storedNum9 != null && storedNum10 != null && storedNum11 != null && storedNum12 != null && storedNum13 != null && storedNum14 != null
                        && storedNum15 != null && storedNum16 != null && storedNum17 != null && storedNum18 != null && storedNum19 != null && storedNum20 != null
                    ) {
                        document.getElementById("puchaseBtn").disabled = false
                    }
                    else {
                        document.getElementById("puchaseBtn").disabled = true
                    }
                    break;

                default:

            }
        }





    }






    useEffect(()=> {

        var eventName = sessionStorage.getItem("EventName")
        var eventData = sessionStorage.getItem("eventData")

        if(eventName == null && eventData == null){
            window.location.href = "http://mazmillions.com/online/";
        }

        sessionStorage.removeItem("ticketsPlayed" )
        sessionStorage.removeItem("Ticket_1_Numbers")
        sessionStorage.removeItem("Ticket_2_Numbers")
        sessionStorage.removeItem("Ticket_3_Numbers")
        sessionStorage.removeItem("Ticket_4_Numbers")
        sessionStorage.removeItem("Ticket_5_Numbers")
        sessionStorage.removeItem("Ticket_6_Numbers")
        sessionStorage.removeItem("Ticket_7_Numbers")
        sessionStorage.removeItem("Ticket_8_Numbers")
        sessionStorage.removeItem("Ticket_9_Numbers")
        sessionStorage.removeItem("Ticket_10_Numbers")
        sessionStorage.removeItem("Ticket_11_Numbers")
        sessionStorage.removeItem("Ticket_12_Numbers")
        sessionStorage.removeItem("Ticket_13_Numbers")
        sessionStorage.removeItem("Ticket_14_Numbers")
        sessionStorage.removeItem("Ticket_15_Numbers")
        sessionStorage.removeItem("Ticket_16_Numbers")
        sessionStorage.removeItem("Ticket_17_Numbers")
        sessionStorage.removeItem("Ticket_18_Numbers")
        sessionStorage.removeItem("Ticket_19_Numbers")
        sessionStorage.removeItem("Ticket_20_Numbers")


        seelectAmt()



            var values = ["0","1", "2", "3", "4", "5" , "6","7","8", "9", "10", "11", "12" , "13", "14", "15", "16", "17", "18", "19", "20"];

            var select = document.createElement("select");
            select.name = "pets";
            select.id = "pets"

            for (const val of values)
            {
                var option = document.createElement("option");
                option.value = val;
                option.text = val.charAt(0).toUpperCase() + val.slice(1);

                option.onSelect = e => console.log("dd")
                select.appendChild(option);
            }

            var label = document.createElement("label");

        select.addEventListener('change', function handleChange(event) {

            sessionStorage.removeItem("ticketsPlayed" )
            sessionStorage.removeItem("ticketsPlayed" )
            sessionStorage.removeItem("Ticket_1_Numbers")
            sessionStorage.removeItem("Ticket_2_Numbers")
            sessionStorage.removeItem("Ticket_3_Numbers")
            sessionStorage.removeItem("Ticket_4_Numbers")
            sessionStorage.removeItem("Ticket_5_Numbers")

            console.log(event.target.value);







        //    document.getElementById("ticket").innerText = event.target.value


            if(event.target.value == 1)
            {
                document.getElementById("fes1").innerHTML = ''
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }
               createTicket1()
                minimizeTicket(1)

            }
            if(event.target.value == 2)
            {
                document.getElementById("fes1").innerHTML = ''
               //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()

                minimizeTicket(1)
                minimizeTicket(2)

            }

            if(event.target.value == 3)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()

                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)

            }

            if(event.target.value == 4)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)


            }

            if(event.target.value == 5)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)


            }

            if(event.target.value == 6)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)


            }

            if(event.target.value == 7)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)


            }

            if(event.target.value == 8)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)


            }

            if(event.target.value == 9)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)


            }

            if(event.target.value == 10)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)


            }

            if(event.target.value == 11)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(11)


            }

            if(event.target.value == 12)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)


            }

            if(event.target.value == 13)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)


            }

            if(event.target.value == 14)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)


            }

            if(event.target.value == 15)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)


            }

            if(event.target.value == 16)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()
                createTicket16()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)
                minimizeTicket(16)


            }

            if(event.target.value == 17)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()
                createTicket16()
                createTicket17()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)
                minimizeTicket(16)
                minimizeTicket(17)


            }

            if(event.target.value == 18)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()
                createTicket16()
                createTicket17()
                createTicket18()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)
                minimizeTicket(16)
                minimizeTicket(17)
                minimizeTicket(18)


            }

            if(event.target.value == 19)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()
                createTicket16()
                createTicket17()
                createTicket18()
                createTicket19()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)
                minimizeTicket(16)
                minimizeTicket(17)
                minimizeTicket(18)
                minimizeTicket(19)


            }

            if(event.target.value == 20)
            {
                document.getElementById("fes1").innerHTML = ''
                //

                var md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile() || md.tablet()) {
                    createTicketUiMobile(event.target.value)
                }
                else {
                    createTicketUi(event.target.value)
                }

                createTicket1()
                createTicket2()
                createTicket3()
                createTicket4()
                createTicket5()
                createTicket6()
                createTicket7()
                createTicket8()
                createTicket9()
                createTicket10()
                createTicket11()
                createTicket12()
                createTicket13()
                createTicket14()
                createTicket15()
                createTicket16()
                createTicket17()
                createTicket18()
                createTicket19()
                createTicket20()


                minimizeTicket(1)
                minimizeTicket(2)
                minimizeTicket(3)
                minimizeTicket(4)
                minimizeTicket(5)
                minimizeTicket(6)
                minimizeTicket(7)
                minimizeTicket(8)
                minimizeTicket(9)
                minimizeTicket(10)
                minimizeTicket(12)
                minimizeTicket(13)
                minimizeTicket(14)
                minimizeTicket(15)
                minimizeTicket(16)
                minimizeTicket(17)
                minimizeTicket(18)
                minimizeTicket(19)
                minimizeTicket(20)


            }





          //  repeat-game




        });


            document.getElementById("container").appendChild(label).appendChild(select);

           // console.clear()

    },  [])




    return ( <>




        <body>
        <div id="container"></div>


        <div id="repeat-game"></div>


        <div id="ticket"></div>




            <div>

            </div>





        </body>















    </> );
}



