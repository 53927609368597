import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "./ItemTypeVideoIcon";

export interface CardNFTProps {
  className?: string;
  isLiked?: boolean;
}

const CardNFT: FC<CardNFTProps> = ({ className = "", isLiked }) => {
  const renderAvatars = () => {
    return (
      <div className="flex -space-x-1 ">
        <Avatar
          containerClassName="ring-2 ring-white dark:ring-neutral-900"
          sizeClass="h-5 w-5 text-sm"
        />
        <Avatar
          containerClassName="ring-2 ring-white dark:ring-neutral-900"
          sizeClass="h-5 w-5 text-sm"
        />
        <Avatar
          containerClassName="ring-2 ring-white dark:ring-neutral-900"
          sizeClass="h-5 w-5 text-sm"
        />
        <Avatar
          containerClassName="ring-2 ring-white dark:ring-neutral-900"
          sizeClass="h-5 w-5 text-sm"
        />
      </div>
    );
  };

  return (
    <div
      className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardNFT"
    >
      <div className="relative flex-shrink-0 ">
        <div>


          <div >


            {/* HEADER */}




                      <div className="col col-xs-12 main-container">
                        <h1>Ticket 1</h1>
                        <div className="input-box">
                          <div id="balls-selector" className="balls-container">

                          </div>

                          <div id="balls-selector1" className="balls-container">

                          </div>
                          <input type="hidden" id="input"/>
                          <button id="check" className="btn btn-primary disabled">Show Selected</button>
                        </div>
                        <div className="output-box hidden">
                          <label htmlFor="matched-count" className="hidden">Trafionych liczb: </label>
                          <span id="matched-count" className="hidden"></span>
                          <div id="matched-numbers" className="balls-container"></div>
                        </div>

                        <div className="restart-box">
                          <button id="restart" className="btn btn-success">Clear</button>
                        </div>
                      </div>









            {/* ====================== END HEADER ====================== */}


          </div>
        </div>

        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>




    </div>
  );
};

export default CardNFT;
