import React, { FC, Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import bckg from "images/776770.png";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import authorBanner from "images/nfts/authorBanner.png";
import collectionBanner from "images/nfts/wsc.png";
import { nftsImgs } from "contains/fakeData";
import NftMoreDropdown from "components/NftMoreDropdown";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import VerifyIcon from "components/VerifyIcon";
import { Tab } from "@headlessui/react";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import ArchiveFilterListBox from "components/ArchiveFilterListBox";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";

import Basic from 'Routes/TransactionHistory'
import Heading from "../../components/Heading/Heading";
import axios from "axios";


export interface TransactionHistoryProps {
    className?: string;
}



const TransactionHistory: FC<TransactionHistoryProps> = ({ className = "" }) => {
    let [categories] = useState([
        "Collectibles",
        "Created",
        "Liked",
        "Following",
        "Followers",
    ]);





    return (
        <Basic/>
    );
};

export default TransactionHistory;
