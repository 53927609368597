import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.png";
import rightLargeImgDark from "images/rightLargeImgDark.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionBecomeAnAuthorProps {
  className?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">

        <h2 className="font-semibold text-3xl sm:text-4xl xl:text-6xl mt-6 sm:mt-10 !leading-[1.112] tracking-tight">
          Maz Millions 6/37 MAX - Stand a chance to win <p className={"milcolor"}>SCR2,000,000</p> plus other prizes  <br />
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400 ">
          6/37 MAX is available only until the 30th December 2023! Purhcase Tickets Now!
        </span>
        <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">

        </div>
      </div>
      <div className="flex-grow">
        <NcImage containerClassName="block dark:hidden" className={"radiusAd"} src={"https://sunshineseychelles.com/wp-content/uploads/mxs-min.png"} />

      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
