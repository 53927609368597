import axios from "axios";
import {Helmet} from "react-helmet";
import NcImage from "../shared/NcImage/NcImage";
import bckg from "../images/776770.png";
import Heading from "../components/Heading/Heading";
import React, {useEffect} from "react";
import { saveAs } from 'file-saver';
import Swal from "sweetalert2";
import IMask from "imask";

export default function Basic() {

    var tries = 4

    useEffect(()=> {



        cardJs()





    },  [])

    function cardJs() {



        const customersNIN = document.getElementById("nin");
        const customersPhone = document.getElementById("phoneNum");

        // const generatecard = document.getElementById("generatecard");

        let cctype = null;


        //Mask the security code
        var nin_mask = new IMask(customersNIN, {
            mask: "000{-}0000{-}0{-}0{-}00"
        });
        var phone_mask = new IMask(customersPhone, {
            mask: "0000000"
        });


        };







    function verifyLocal() {


        var verificationCode = Math.floor(Math.random() * (1000000 - 100000) + 100000)

        console.log("The Verification Code Is: " + verificationCode)

        let customersPhone = document.getElementById("phoneNum").value
        let customersNin = document.getElementById("nin").value


        if(customersNin != "" && customersPhone != "" && customersPhone.length > 6){



        var verCodeMessage = "Your Verification Code is: " + verificationCode

        axios
            .get('https://cmp.cwseychelles.com/cgi-bin/BMP_SendTextMsg?UserName=MAZLOTTERY&PassWord=MyLcxD_123&UserData=' + verCodeMessage +'&Concatenated=1&SenderId=MAZMILLION%20R&Deferred=false&Number=' + customersPhone + '&Dsr=true')
            .then(res => {
                console.log(`statusCode: ${res.status}`)
                console.log(res)
            })
            .catch(error => {
                console.error(error)
            })

        Swal.fire({
            title: "Verification",
            text: "A Verification code has been sent to " + customersPhone + ", Please enter the code before proceeding",
            input: 'number',
            showCancelButton: true
        }).then((result) => {
            if (result.value == verificationCode) {
                console.log("Success, you are a local! : " + result.value);


                checkTransHistory()


            }
            else{

                console.log("Failure, you are not local! "  );
                tries = tries - 1

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid Verification Code, Try Again! You have ' + tries + ' more tries',

                })

                if(tries == 0){
                    window.location.href = "http://mazmillions.com/online/";
                }
            }
        });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Check your Phone Number and NIN Fields before proceeding',

            })

        }
        return

    }


    function checkTransHistory() {

        let timerInterval
        Swal.fire({
            title: 'Downloading Transaction History!',
            html: 'Auto Download in <b></b> .',
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })

        // @ts-ignore
        var custNum = document.getElementById("phoneNum").value

        // @ts-ignore
        var custNin = document.getElementById("nin").value


        console.log(custNum + custNin)






        axios.post('http://fafafasfwq.website/create-transactionhistory', {custNum: custNum, custNin: custNin})
            .then(() => axios.get('http://fafafasfwq.website/fetch-transactionhistory', {responseType: 'blob' , params: {custNum: custNum, custNin: custNin, responseType: 'blob' }}))
            .then((res) => {
                const pdfBlob = new Blob([res.data], {type: 'application/pdf'});

                saveAs(pdfBlob, `11_23_transactionhistory_mazmillions.pdf`);


            })
            .catch(error => {
                console.log("error")
                console.log(error)
            })
    }


    return (
        <div   data-nc-id="AuthorPage">
            <Helmet>
                <title>Maz Millions Lottery - Online</title>
            </Helmet>


            <div className="w-full">
                <div className="relative w-full h-40 md:h-60 2xl:h-72">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={"https://absolutecaresey.com/wp-content/uploads/2022/12/Untitled-1-01.jpg"}
                        className="object-cover w-full h-full"
                    />
                </div>

            </div>
            {/* ====================== END HEADER ====================== */}


            <div
                className={`nc-PageCollection  `}
                data-nc-id="PageCollection"
            >


                {/* ====================== END HEADER ====================== */}

                <br/>
                <br/>

                <Heading
                    isCenter={true}


                >
                    Check your transaction history <h1> Enter your details below </h1>
                </Heading>

                <div className="text-input form-group mt-2 d-inline-block">
                    <span className="border-end country-code px-2">+248</span>
                    <input type="text" id="phoneNum" placeholder="Phone Number"/>

                </div>

                <br/>
                <br/>

                <div className="text-input form-group mt-2 d-inline-block">

                    <input type="text" id="nin" placeholder="N-I-N"/>

                </div>
                <br/>
                <div>
                    <button onClick={verifyLocal} className={"button-checkhis"}> Check History</button>

                </div>
            </div>

        </div>
    );
}
