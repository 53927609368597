import React from 'react';

const AddToHomeScreenPopup = () => {
    return (
        <div className="add-to-home-screen-popup">
            <p>Do you want to add this web app to your home screen?</p>
            <button  >Add to Home Screen</button>
        </div>
    );
};

export default AddToHomeScreenPopup;
