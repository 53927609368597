import React, { FC } from "react";
import MainNav2Logged from "./MainNav2Logged";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
      <div className="sticky-wrapper">
          <head>
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
          </head>

          <div className="nc-HeaderLogged relative w-full z-40 sticky-wrapper">
              <div className="topnav">
                  <a className="active" href="#home">Home</a>
                  <a className="info" href="https://www.facebook.com/SeyMazMillion">Facebook</a>
                  <p className="account-balance">
                      <i className="fas fa-wallet"></i> <b>Wallet: SCR 0.00 </b>
                  </p>
              </div>
          </div>
          <MainNav2Logged />
      </div>



  );
};

export default HeaderLogged;
