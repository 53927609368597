

import axios from "axios";
import {useEffect, useState} from "react";
import React, { Component }  from 'react';
import {nftsImgs} from "../contains/fakeData";
import {Helmet} from "react-helmet";
import NcImage from "../shared/NcImage/NcImage";
import authorBanner from "../images/nfts/authorBanner.png";
import VerifyIcon from "../components/VerifyIcon";
import Basic from "./TicketAmount";
import CardNFT from "../components/CardNFT";


export default function BasicTick() {




    const [bestperformingdata, setBestperformingdata] = useState([


    ]);








    function createTicket2()
    {
        (function(window, document) {
            // Constants
            const LOTEK_SIZE = 36;
            const LOTEK_SELECTION_SIZE = 6;
            const Lotek = (function() {
                const getNumbers = Symbol("getNumbers");
                const numbersMap = new WeakMap();
                let c = class {
                    constructor() {
                        numbersMap.set(this, this[getNumbers]());
                    }
                    [getNumbers]() {
                        return new Set(
                            [...Array(LOTEK_SIZE).keys()]
                                .map(x => x + 1)
                                .sort(() => Math.random() - 0.5) // shuffle
                                .slice(0, LOTEK_SELECTION_SIZE)
                        );
                    }
                    checkNumbers(...numbers) {
                        numbers = [
                            ...new Set(
                                numbers.filter(x => Number.isInteger(x) && x >= 1 && x <= LOTEK_SIZE)
                            )
                        ];
                        if (numbers.length !== LOTEK_SELECTION_SIZE) {
                            throw new Error(
                                `Należy podać ${LOTEK_SELECTION_SIZE} różnych liczb całkowitych od 1 do ${LOTEK_SIZE}.`
                            );
                        }
                        let correctNumbers = numbersMap.get(this);
                        let matchedNumbers = numbers.filter(x => correctNumbers.has(x));
                        return {
                            numbers: matchedNumbers,
                            count: matchedNumbers.length
                        };
                    }
                    *[Symbol.iterator]() {
                        yield* numbersMap.get(this);
                    }
                };
                return c;
            })();
            // ----

            // Classes
            class EventEmitter {
                constructor() {
                    this._events = [];
                }
                on(eventName, fn) {
                    if (!this._events.hasOwnProperty(eventName)) {
                        this._events[eventName] = [];
                    }
                    this._events[eventName].push(fn);
                    return this;
                }
                emit(eventName, ...args) {
                    for (let fn of this._events[eventName] || []) {
                        fn(...args);
                    }
                    return this;
                }
            }
            class LotekSelector extends EventEmitter {
                constructor() {
                    super();
                    this._numbers = new Set();
                }
                add(number) {
                    if (!this._validateNumber(number)) {
                        throw new Error("Number is invalid: " + number);
                    }
                    if (this._numbers.size === LOTEK_SELECTION_SIZE) {
                        throw new SelectorFullError();
                    }
                    this._numbers.add(number);
                    this.emit("add", number);
                    return this;
                }
                remove(number) {
                    this._numbers.delete(number);
                    this.emit("remove", number);
                    return this;
                }
                clear() {
                    for (let number of this) {
                        this.remove(number);
                    }
                    return this;
                }
                toggle(number) {
                    if (this.has(number)) {
                        this.remove(number);
                    } else {
                        this.add(number);
                    }
                    return this;
                }
                isFull() {
                    return this._numbers.size >= LOTEK_SELECTION_SIZE;
                }
                has(number) {
                    return this._numbers.has(number);
                }
                *[Symbol.iterator]() {
                    yield* this._numbers;
                }
                _validateNumber(number) {
                    return Number.isInteger(number) && number >= 1 && number <= LOTEK_SIZE;
                }
            }
            class SelectorFullError extends Error {
                constructor() {
                    super("Selector is full.");
                }
            }
            // ----

            // Utils
            function $(selector) {
                return document.querySelector(selector);
            }
            function $$(selector, fn) {
                for (let el of document.querySelectorAll(selector)) {
                    fn(el);
                }
            }
            function show(dom) {
                dom.classList.remove("hidden");
            }
            // ----

            // Rendering functions
            function clearFields() {
                lotekSelector.clear();
                $$("#matched-count, #matched-numbers, #results", el => {
                    el.innerHTML = "";
                });
            }
            function clearError() {
                $("#matched-numbers").innerHTML = "";
            }
            function renderCheck(numbers, matchedNumbers, count) {
                $("#matched-count").innerHTML = count;
                let matchedNumbersHtml = "";
                for (let number of numbers) {
                    let numberClass = matchedNumbers.indexOf(number) !== -1 ? "active" : "";
                    matchedNumbersHtml += `<span class="ball ${numberClass}">${number}</span>`;
                }
                $("#matched-numbers").innerHTML = matchedNumbersHtml;
                show($(".output-box"));
            }
            function renderResults(lotek) {
                let html = "";
                for (let number of lotek) {
                    html += `<span class="ball active result-number">${number}</span>`;
                }
                $("#results").innerHTML = html;
            }
            function renderError(error) {
                let message = error instanceof Error ? error.message : error + "";
                $("#matched-numbers").innerHTML = `
      <span class="error">${message}</span>
    `;
                show($(".output-box"));
            }
            function renderBallsSelector(container) {
                for (let n of [...Array(LOTEK_SIZE).keys()]) {
                    container.innerHTML += `<div class="ball" data-number="${n + 1}">${n +
                    1}</div>`;
                }
            }
            // ----

            // Run - balls selector
            renderBallsSelector($("#balls-selector"));



            let lotekSelector = new LotekSelector();
            lotekSelector
                .on("add", function(number) {
                    let dom = $(`.ball[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.add("active");
                    }
                    if (lotekSelector.isFull()) {
                        $("#check").classList.remove("disabled");
                    }
                })
                .on("remove", function(number) {
                    let dom = $(`.ball[data-number="${number}"]`);
                    if (dom) {
                        dom.classList.remove("active");
                    }
                    if (!lotekSelector.isFull()) {
                        $("#check").classList.add("disabled");
                    }
                });
            $$("#balls-selector .ball", function(el) {
                el.addEventListener("click", function(e) {
                    clearError();
                    try {
                        lotekSelector.toggle(Number(this.dataset.number));
                    } catch (err) {
                        if (err instanceof SelectorFullError) {
                            renderError("Maximum 6 numbers already selected");
                        } else {
                            throw err;
                        }
                    }
                });
            });
            // ----



            // Run - buttons
            let myLotek = new Lotek();
            $("#check").addEventListener("click", function(e) {
                if (this.classList.contains("disabled")) {
                    return;
                }
                let numbers = [...lotekSelector];
                try {
                    let { numbers: matchedNumbers, count: matchedCount } = myLotek.checkNumbers(
                        ...numbers
                    );
                    renderCheck(numbers, matchedNumbers, matchedCount);
                } catch (e) {
                    renderError(e);
                }
            });
            $("#restart").addEventListener("click", function(e) {
                myLotek = new Lotek();
                clearFields();
            });
            // ----
        })(window, document);
    }




    function onClickPurchase()
    {
        var tickAmt = document.getElementById("pets").value

        sessionStorage.setItem("ticketsPlayed", tickAmt)

        window.location.href = "http://mazmillions.com/purchaseTickets";
    }






    useEffect(()=> {


        document.getElementById("puchaseBtn").disabled = true

       var tickAmt = document.getElementById("pets").value

        ////console.log(" VALUE ! - " + tickAmt)

        /*
               for(var i=0; i< 2; i++)
               {


               // Create Ticket Element ---------------------------------------------------------------------------------------------------


               let div_wfull = document.createElement('div')


               let containerm10 = document.createElement('div')
               containerm10.className = "max-w-sm2"

               let relativemw = document.createElement('div')
               relativemw.className = "relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl2 flex flex-col md:flex-row"

               let pt_5 = document.createElement('div')


               let max_w = document.createElement('div')


               let col = document.createElement('div')
               col.className = "col col-xs-12 main-container"


               let input_box2  = document.createElement('div')
               input_box2.className = "input-box2"

               let ball_container  = document.createElement('div')
               ball_container.className = "balls-container2"
               ball_container.id = "balls-selector2"

               let hiddeninput  = document.createElement('input')
               hiddeninput.id = "input"
               hiddeninput.type = "hidden"

               let checkbutton  = document.createElement('button')
               checkbutton.className = "btn btn-primary disabled button-71 "
               checkbutton.id = "check2"

               checkbutton.innerText = "Confirm"

               let output_box  = document.createElement('button')
               output_box.className = "output-box hidden2"

               let max6ticketslabel  = document.createElement('label')
               max6ticketslabel.className = "hidden"
               max6ticketslabel.htmlFor = "matched-count2"
               max6ticketslabel.innerText = " Max Numbers Already selected "

               let spanlabel  = document.createElement('span')
               spanlabel.id = "matched-count2"
               spanlabel.className = "hidden"

               let spanlabelseparator  = document.createElement('span')
               spanlabelseparator.className = "visible"

               spanlabelseparator.innerText = " ------------------------- "

               let matched_numbers20  = document.createElement('div')
               matched_numbers20.id = "matched-numbers2"
               matched_numbers20.className = "balls-container2"

               let clearbutton  = document.createElement('restart-box2')
               clearbutton.id = "restart2"
               clearbutton.className = "btn btn-success button-24"

               clearbutton.innerText = "Clear"

                   let emptyspace  = document.createElement('br')
                   let emptyspace1  = document.createElement('br')
                   let emptyspace2  = document.createElement('br')
                   let emptyspace3  = document.createElement('br')
                   let emptyspace4  = document.createElement('br')









               div_wfull.appendChild(containerm10)
               containerm10.appendChild(relativemw)
               relativemw.appendChild(pt_5)
               pt_5.appendChild(max_w)
               max_w.appendChild(col)

               output_box.appendChild(clearbutton)
               output_box.appendChild(checkbutton)


               col.innerHTML = `<h7> Ticket 2 - This is ticket number: 2</h7>`

               col.appendChild(input_box2)
               input_box2.appendChild(ball_container)
               ball_container.appendChild(hiddeninput)



               output_box.appendChild(max6ticketslabel)
               output_box.appendChild(spanlabel)
               output_box.appendChild(spanlabelseparator)


               output_box.appendChild(matched_numbers20)


               max_w.appendChild(output_box)

                   div_wfull.appendChild(emptyspace)
                   div_wfull.appendChild(emptyspace1)
                   div_wfull.appendChild(emptyspace2)
                   div_wfull.appendChild(emptyspace3)
                   div_wfull.appendChild(emptyspace4)







               let fes = document.getElementById("fes")
               fes.appendChild(div_wfull)
               }

               // Create Ticket Element ---------------------------------------------------------------------------------------------------





                       document.getElementById("sssss").innerHTML = `

                         <div className="w-full">

                               <div className="container -mt-10 lg:-mt-16">
                                   <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">

                                       <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
                                           <div className="max-w-screen-sm ">


                                               //ball selectioe
                                               <div className="col col-xs-12 main-container">
                                                   <h1>Ticket 2</h1>
                                                   <div className="input-box2">
                                                       <div id="balls-selector2" className="balls-container2">

                                                       </div>

                                                       <input type="hidden2" id="input"/>
                                                       <button id="check2" className="btn btn-primary disabled">Show Selected</button>
                                                   </div>

                                                   <div className="output-box hidden2">
                                                       <label htmlFor="matched-count2" className="hidden2">Trafionych liczb: </label>
                                                       <span id="matched-count2" className="hidden2"></span>
                                                       <div id="matched-numbers2" className="balls-container2"></div>
                                                   </div>

                                                   <div className="restart-box2">
                                                       <button id="restart2" className="btn btn-success">Clear</button>
                                                   </div>

                                               </div>




                                           </div>

                                       </div>

                                   </div>
                               </div>
                           </div>

                       `

               */

        //console.clear()
    },  [])




    return ( <>



        <div >



            <div className={"grid sm:grid-cols-2 gap-x-5 lg:grid-cols-4 xl:gap-x-8"} id={"fes1"}></div>



            {/* HEADER */}

            {/* ====================== END HEADER ====================== */}
            <br/>

            <div id="ss"> <button id={"puchaseBtn"} onClick={onClickPurchase} className={"btn btn-primary disabled button-71"}>PURCHASE </button></div>
            <br/>
        </div>









    </> );
}



